import React, { useState, useEffect } from 'react';
import {
    AppBar,
    Tab,
    Tabs,
    Box,
    Card,
    Container,
    makeStyles, 
    Typography
} from '@material-ui/core';

import clsx from 'clsx';
import Page from 'src/components/Page';
import AuthCheck from 'src/components/auth-check';

import { useRecoilValue } from 'recoil';
import { loggedInUserAtom } from 'src/app-data/atoms/user-atom';
import { NewTrode } from './NewTrode';
import { NewTrodeType } from './NewTrodeType';
import { NewTrodeSize } from './NewTrodeSize';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "1400px",
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    flexGrow: 1
  },
  card: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    color: theme.palette.text.primary
  },
  tabBar: {
    backgroundColor: "#4083c2"
  },
  tabPanel: {
    paddingTop: theme.spacing(3),
  }
}));

const TabPanel = (props) => {
    const { children, value, ...other } = props;
    const classes = useStyles();

    return (
        <div
          role="tabpanel"
          id={`simple-tabpanel-${value}`}
          aria-labelledby={`simple-tab-${value}`}
          {...other}
          className={classes.tabPanel}
        >
          {children}
        </div>
      );
}

const TrodeCreateView = ({ className, ...rest }) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [currentComponent, setCurrentComponent] = useState(<NewTrode />);
    const currentUser = useRecoilValue(loggedInUserAtom);
    const [show, setShow] = useState(false);

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);

        switch(newValue) {
            case 0:
                setCurrentComponent(<NewTrode />);
                break;
            case 1:
                setCurrentComponent(<NewTrodeType />);
                break;
            case 2:
                setCurrentComponent(<NewTrodeSize />);
                break;
            default:
                setCurrentComponent(<NewTrode />);
                break;
        }
    };

    const hasMinimumGroup = (group) => {
        if(currentUser?.groups?.includes('SuperUser')) {
            return true;
        }
        else {
            switch(group) {
                case 'Admin': return currentUser?.groups?.includes('Admin');
                case 'PulseVetUser': return currentUser?.groups.length !== 0;
                default: return false;
            }
        }
    }

    useEffect(() => {
        if(hasMinimumGroup('Admin')) {
            setShow(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AuthCheck>
        <Page className={classes.root} title="Create New">
            <Container maxWidth={false}>
                <Typography color="textPrimary" variant="h4">
                    Create New
                </Typography>

                <Card
                    className={clsx(classes.card, className)}
                    {...rest}
                >
                    <AppBar position="static">
                        <Tabs 
                            value={value} 
                            onChange={handleChange} 
                            aria-label="simple tabs example"
                            className={classes.tabBar}
                            TabIndicatorProps={{
                                style: {
                                backgroundColor: "#fff",
                                height: "3px"
                                }
                            }}
                        >
                            <Tab label="New Trode" {...a11yProps(0)} />
                            { show && (<Tab label="New Trode Type" {...a11yProps(1)} />) }
                            { show && (<Tab label="New Trode Size" {...a11yProps(2)} />) }
                        </Tabs>
                    </AppBar>

                    <TabPanel value={value}>
                        {currentComponent}
                    </TabPanel>

                    <Box display="flex" justifyContent="flex-end" p={2}>
                        <Typography
                            color="textSecondary"
                            variant="caption"
                        >
                            Create New Administration
                        </Typography>
                    </Box>
                </Card>
            </Container>
        </Page>
        </AuthCheck>
    );
};

export default TrodeCreateView;