import React, { useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles, 
  Typography
} from '@material-ui/core';

import Page from 'src/components/Page';
import AuthCheck from 'src/components/auth-check';
import Results from './Results';
import Toolbar from './Toolbar';

import { useRecoilCallback } from 'recoil';
import { getCustomersDisplayAtom, getCustomersFullAtom } from 'src/app-data/atoms/customers-atom';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "1400px",
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  card: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    color: theme.palette.text.error
  }
}));

const CustomerListView = () => {
  const classes = useStyles();

  const resetDataList = useRecoilCallback(({reset}) => async () => {
    reset(getCustomersDisplayAtom);
    reset(getCustomersFullAtom);
  });

  useEffect(() => {
    resetDataList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthCheck>
    <Page
      className={classes.root}
      title="Customer Search"
    >
      <Container maxWidth={false}>
        <Typography color="textPrimary" variant="h4">
          Customer Search
        </Typography>

        <Toolbar />

        <Box mt={3}>
          <Results />
        </Box>
      </Container>
    </Page>
    </AuthCheck>
  );
};

export default CustomerListView;