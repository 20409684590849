import React from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import AuthCheck from 'src/components/auth-check';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';
import Password from './Password';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "1400px",
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const UserProfileView = () => {
  const classes = useStyles();

  return (
    <AuthCheck>
    <Page className={classes.root} title="User Profile">
      <Container maxWidth={false}>
        <Typography color="textPrimary" variant="h4">
          User Profile
        </Typography>

        <Box mt={3}>
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <Profile />
            </Grid>

            <Grid item lg={8} md={6} xs={12}>
              <ProfileDetails />
            </Grid>

            <Grid item xs={12}>
              <Password />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
    </AuthCheck>
  );
};

export default UserProfileView;