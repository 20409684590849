import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from "aws-amplify";

var jwt = require('jsonwebtoken');


const AuthCheck = (props) => {
    const navigate = useNavigate();
    const [loadPage, setLoadPage] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                //Amplify issue. calling Auth.currentAuthenticatedUser() throws an exeption if the user is not authenticatd.
                // https://github.com/aws-amplify/amplify-js/issues/5167
                const usr = await Auth.currentAuthenticatedUser();
                const decoded = jwt.decode(usr?.storage?.access_token);

                if (!decoded || Date.now() >= decoded.exp * 1000) {
                    await Auth.signOut();
                    localStorage.clear();
                    return navigate('/', { replace: true });
                }
                else {
                    setLoadPage(true);
                }
            }
            catch(err) { 
                setLoadPage(false);
                localStorage.clear(); 
                return navigate('/', { replace: true });
            }
        })();

        return () => {
            setLoadPage(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    return ( loadPage ?
        <>
            {props.children}
        </>
        : 
        <></>
    );
};

export default AuthCheck;