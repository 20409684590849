import { atom } from "recoil";

export const getDashboadCountsAtom = atom({
    key: 'DASHBOARD_COUNTS',
    default: {}
});

export const getDashboadCustomersAtom = atom({
    key: 'DASHBOARD_CUSTOMERS',
    default: []
});