import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  //makeStyles
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Auth } from "aws-amplify";

import { useRecoilState } from 'recoil';
import { loggedInUserAtom } from 'src/app-data/atoms/user-atom';


// const useStyles = makeStyles(() => ({
//   root: {}
// }));

const ProfileDetails = ({ className, ...rest }) => {
  //const classes = useStyles();

  const [_isSubmitting, updateIsSubmitting] = useState(false);
  const [displayMessage, updateDisplayMessage] = useState({
    message: "",
    show: false,
    severity: "", //error, warning, info, success
    showAction: false
  });

  const [userState, setUserState] = useRecoilState(loggedInUserAtom);
  //const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const handleSubmit = (values, validateFunc, setTouched) => {
    const { firstName, lastName } = values; //phoneNumber
    updateDisplayMessage({ message: "", show: false, severity: "" });

    (async () => {
      try {
        const valError = await validateFunc();

        if (Object.keys(valError).length > 0) {
          setTouched(valError);
          updateIsSubmitting(false);
        }
        else {
          updateIsSubmitting(true);

          Auth.currentAuthenticatedUser()
          .then(user => { 
            Auth.updateUserAttributes(user, {
              'family_name': lastName,
              'given_name': firstName,
              //'phone_number': phoneNumber || '',
              'updated_at': Date.now().toString()
            })
              .then(res => {
                setUserState({
                  ...userState,
                  ...{
                    'family_name': lastName,
                    'given_name': firstName,
                    //'phone_number': phoneNumber || ''
                  }
                });

                updateDisplayMessage({
                  message: "Your information has been updated.",
                  show: true,
                  severity: "success",
                  showAction: false
                });
              })
              .catch(err => {
                console.log(err);
                updateDisplayMessage({
                  message: `There was an error changing your information.`,
                  show: true,
                  severity: "error",
                  showAction: false
                });
              });
          })
          .catch(err => {
            updateDisplayMessage({
              message: `Unauthorized. Log out and then log back in.`,
              show: true,
              severity: "error",
              showAction: false
            });
          });

          updateIsSubmitting(false);
        }
      }
      catch (error) {
        updateDisplayMessage({
          message: `There was a system error: ${error && error.message}`,
          show: true,
          severity: "error",
          showAction: false
        });
      }
    })();
  };

  return (
    <Formik
      initialValues={{
        firstName: userState.given_name,
        lastName: userState.family_name,
        //phoneNumber: userState.phone_number || ''
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().max(50, 'First Name can be a max of 50 characters').required('First Name is required'),
        lastName: Yup.string().max(50, 'Last Name can be a max of 50 characters').required('Last Name is required'),
        //phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
      })}
    >
      {({ errors, handleBlur, handleChange, handleReset, validateForm, setTouched, touched, values }) => (
        <>
          <Card>
            <CardHeader
              title="Profile"
              subheader="All fields are required"
            />

            <Divider />

            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    type="text"
                    SelectProps={{ native: true }}
                    value={values.firstName}
                    variant="outlined"
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    type="text"
                    SelectProps={{ native: true }}
                    value={values.lastName}
                    variant="outlined"
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                {/* <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="phoneNumber"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    SelectProps={{ native: true }}
                    value={values.phoneNumber}
                    variant="outlined"
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    InputLabelProps={{
                      shrink: true
                    }}
                    helperText="Valid Format: +14325551212 Must start with plus sign and no other special cahracters"
                  />
                </Grid> */}

                {displayMessage.show &&
                  <Grid item xs={12}>
                    <Alert severity={displayMessage.severity}>
                      {displayMessage.message}
                    </Alert>
                  </Grid>
                }
              </Grid>
            </CardContent>

            <Divider />

            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleSubmit(values, validateForm, setTouched)}
                disabled={_isSubmitting}
                type="submit"
              >
                Save Details
              </Button>
            </Box>
          </Card>
        </>
      )}
    </Formik>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;
