import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Auth } from "aws-amplify";


const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    color: theme.palette.text.error
  },
  reset: {
    marginRight: theme.spacing(1)
  }
}));

const Password = ({ className, ...rest }) => {
  const classes = useStyles();

  const [_isSubmitting, updateIsSubmitting] = useState(false);
  const [displayMessage, updateDisplayMessage] = useState({
    message: "",
    show: false,
    severity: "", //error, warning, info, success
    showAction: false
  });

  const handleCleanup = (resetFunc) => {
    updateIsSubmitting(false);
    updateDisplayMessage({ message: "", show: false, severity: "" });

    resetFunc?.();
  };

  const handleSubmit = (values, validateFunc, setTouched, resetFunc) => {
    const { oldPassword, newPassword } = values;

    (async () => {
      try {
        const valError = await validateFunc();

        if (Object.keys(valError).length > 0) {
          setTouched(valError);
          updateIsSubmitting(false);
        }
        else {
          updateIsSubmitting(true);

          Auth.currentAuthenticatedUser()
          .then(user => {
              return Auth.changePassword(user, oldPassword, newPassword);
          })
          .then(data => {
            //console.log("PASSWORD CHANGED", data);

            updateDisplayMessage({
              message: "Your password has been updated.",
              show: true,
              severity: "success",
              showAction: false
            });
          })
          .catch(err => {
            //console.log("PASSWORD CHANGE ERROR", err);

            updateDisplayMessage({
              message: `There was an error changing your password.`,
              show: true,
              severity: "error",
              showAction: false
            });
          });

          resetFunc?.();
          updateIsSubmitting(false);
        }
      }
      catch (error) {
        updateDisplayMessage({
          message: `There was a system error: ${error && error.message}`,
          show: true,
          severity: "error",
          showAction: false
        });
      }
    })();
  };

  return (
    <Formik
      initialValues={{
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      }}
      validationSchema={Yup.object().shape({
        oldPassword: Yup.string().max(50, 'Old Password can be a max of 50 characters').required('Old Password is required'),
        newPassword: Yup.string().max(50, 'New Password can be a max of 50 characters').required('New Password is required'),
        confirmPassword: Yup.string()
          .max(50, 'Confirm New Password can be a max of 50 characters')
          .required('Confirm New Password is required')
          .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      })}
    >
      {({ errors, handleBlur, handleChange, handleReset, validateForm, setTouched, touched, values }) => (
        <>
          <Card>
            <CardHeader
              title={'Change Password'}
              subheader="All fields are required"
            />

            <Divider />

            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Old Password"
                    name="oldPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    type="password"
                    SelectProps={{ native: true }}
                    value={values.oldPassword}
                    variant="outlined"
                    error={Boolean(touched.oldPassword && errors.oldPassword)}
                    helperText={touched.oldPassword && errors.oldPassword}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Alert severity="warning">
                    <b>New Password Requirments</b><br />
                    Minimum length 8, Requires at least 1 of the following: Upper and lower case letters and at least one number.
                  </Alert>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="New Password"
                    name="newPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    type="password"
                    SelectProps={{ native: true }}
                    value={values.newPassword}
                    variant="outlined"
                    error={Boolean(touched.newPassword && errors.newPassword)}
                    helperText={touched.newPassword && errors.newPassword}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Confirm New Password"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    type="password"
                    SelectProps={{ native: true }}
                    value={values.confirmPassword}
                    variant="outlined"
                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                {displayMessage.show &&
                  <Grid item xs={12}>
                    <Alert
                      severity={displayMessage.severity}
                    >
                      {displayMessage.message}
                    </Alert>
                  </Grid>
                }
              </Grid>
            </CardContent>

            <Divider />

            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button 
                color="primary" 
                variant="contained" 
                onClick={() => handleCleanup(handleReset)}  
                className={classes.reset}
              >
                Reset
              </Button>

              <Button
                color="primary"
                variant="contained"
                onClick={() => handleSubmit(values, validateForm, setTouched, handleReset)}
                disabled={_isSubmitting}
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </Card>
        </>
      )}
    </Formik>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
