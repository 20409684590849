import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReactToPrint from 'react-to-print';

import { useRecoilState, useSetRecoilState, useRecoilCallback } from 'recoil';
import { getCustomersPartialAtom } from 'src/app-data/atoms/customers-atom';
import { reportReturnTrodeFormAtom } from 'src/app-data/atoms/reports-atom';
import { httpClient } from 'src/lib/api-factory';

const useStyles = makeStyles((theme) => ({
  root: {},
  actiontButtonContainer: {
    marginBottom: theme.spacing(3)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  }
}));

const Toolbar = ({ className, componentToPrintRef, ...rest }) => {
  const classes = useStyles();

  const [displayMessage, updateDisplayMessage] = useState({
    message: "",
    show: false,
    severity: "", //error, warning, info, success
    showAction: false
  });

  const [_isSubmitting, updateIsSubmitting] = useState(false);
  const [customers, setCustomers] = useRecoilState(getCustomersPartialAtom); 
  const setReportData = useSetRecoilState(reportReturnTrodeFormAtom);


  const getCustomers = useRecoilCallback(() => async () => {
    try {
        const results = await httpClient().get("/customers");
        return results.data;
    }
    catch(error) {
        return [];
    }    
  });

  const getCustomerById = useRecoilCallback(() => async (id) => { 
    try {
        const results = await httpClient().get(`/customers/${id}/type/accountId`);
        return results.data;
    }
    catch(error) {
        return [];
    }    
  });

  const handleSubmit = (values, validateFunc, setTouched) => { 
    const { customerAccountId } = values;

    (async () => {
      try {
        const valError = await validateFunc();

        if(Object.keys(valError).length > 0) {
          setTouched(valError);
          updateIsSubmitting(false);
        }
        else {
          updateIsSubmitting(true);

          var reportData = await getCustomerById(customerAccountId);

          setReportData(reportData);
          
          if(Object.keys(reportData).length > 0) {
            updateDisplayMessage({ 
              message: "",
              show: false,
              severity: "success"
            });
          }
          else {
            updateDisplayMessage({ 
              message: "No records were found. Revise your search criteria and try searching again.",
              show: true,
              severity: "warning"
            });
          }
        }

        updateIsSubmitting(false);
      }
      catch(error) {
        updateDisplayMessage({ 
          message: (error && error.message) || "There was an error fetching the data",
          show: true,
          severity: "error"
        });
      }
    })();
  }

  useEffect(() => {
    (async () => {
      try {
        const customersResponse = await getCustomers();
        setCustomers(customersResponse);
      }
      catch(error) {

      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <Formik
      initialValues={{
          customerAccountId: '0'
      }}
      validationSchema={Yup.object().shape({
          customerAccountId: Yup.string().notOneOf(['0', '', 0], 'Customer is required').required('Customer is required')
      })}
    >
      {({errors, handleBlur, handleChange, handleReset, validateForm, setTouched, touched, values, initialValues}) => (
      <>
        <div className={clsx(classes.root, className)}
          {...rest}
        >
          <Box display="flex" justifyContent="flex-end" className={classes.actiontButtonContainer}>
            <ReactToPrint
              trigger={() => 
                <Button color="primary" variant="contained">
                  Print
                </Button>
              }
              content={() => componentToPrintRef.current}
            />
          </Box>

          <Card>
            <CardHeader
              title={`Return Trode`}
              subheader="Begin by selecting a customer."
            />

            <Divider />

            <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Autocomplete
                      id="combo-box-customers"
                      options={customers}
                      fullWidth
                      getOptionLabel={(option) => `${option.customerName} #${option.customerNumber}`}
                      onChange={(event, value) => values.customerAccountId = value && value.customerAccountId}
                      renderOption={(option) => (
                        <React.Fragment>
                          <span style={{width: 60}}>#{option.customerNumber}</span>
                          {option.customerName}
                        </React.Fragment>
                        )} 
                        renderInput={
                          (params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Select Customer"
                              name="customerAccountId"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              required
                              SelectProps={{ native: true }}
                              value={values.customerAccountId}
                              variant="outlined"
                              error={Boolean(touched.customerAccountId && errors.customerAccountId)}
                              helperText={touched.customerAccountId && errors.customerAccountId}
                              style={{maxWidth: 500}}
                            />
                          )
                        }
                    />
                  </Grid>

                  {displayMessage.show &&
                  <Grid item md={12} xs={12}>
                    <Alert severity={displayMessage.severity}>
                      {displayMessage.message}
                    </Alert>
                  </Grid>
                  }
                </Grid>
            </CardContent>

            <Divider />

            <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
            >
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleSubmit(values, validateForm, setTouched)}
                    disabled={_isSubmitting}
                    type="submit"
                >
                    { _isSubmitting ? 'Fetching Data...' : 'Submit' }
                </Button>
            </Box>
        </Card>
        </div>
      </>
      )}
    </Formik>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
