import React, { useState } from 'react';
import { 
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    makeStyles,
    FormControlLabel,
    Switch,
    FormLabel,
    FormControl,
    FormGroup
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { Formik } from 'formik';
import * as Yup from 'yup';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { useRecoilValue, useRecoilState, useSetRecoilState, useRecoilCallback } from 'recoil';
import { trodeSearchSerialNumberAtom, availableTrodesAtom, availableTrodeSizesAtom } from 'src/app-data/atoms/trodes-atom';
import { loggedInUserAtom } from 'src/app-data/atoms/user-atom';
import { getToAPISelector, postToAPISelector, useSetPulseVetApiState } from 'src/app-data/triggers/api-triggers';
import { formatDate } from 'src/lib/date-functions';

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        fontSize: "0.850rem"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    formControlLabel: {
        fontSize: "0.850rem",
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    formControlContainer: {
        marginLeft: theme.spacing(1)
    }
}));

export const AlterTrodeModal = (props) => {
    const { trode } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [savedToDb, setSavedToDb] = useState(false);
    const [switches, setSwitches] = useState({
        retired: trode.retired
    });

    const [_isSubmitting, updateIsSubmitting] = useState(false);
    const [disableReset, updateDisableReset] = useState(false);
    const [displayMessage, updateDisplayMessage] = useState({
        message: "",
        show: false,
        severity: "", //error, warning, info, success
        showAction: false
    });

    const setSerialNumber = useSetRecoilState(trodeSearchSerialNumberAtom);
    const trodeSizes = useRecoilValue(availableTrodeSizesAtom);
    const [trodeList, setAvailableTrodes] = useRecoilState(availableTrodesAtom); 
    const refreshTrodes = useSetPulseVetApiState(availableTrodesAtom);
    const currentUser = useRecoilValue(loggedInUserAtom);

    const handleClickOpen = () => setOpen(true);

    const handleClose = (resetFunc) => {
        setOpen(false);
        handleCleanup(resetFunc);
        
        if(savedToDb) { 
            if(trodeList.length === 1) {
                setAvailableTrodes([]);
            }
            else {
                refreshTrodes({
                    type: "GET",
                    uri: "/trodes"
                });
            }
        }
    };

    const handleCleanup = (resetFunc) => {
        updateIsSubmitting(false);
        updateDisplayMessage({ message: "", show: false, severity: "" });
        setSerialNumber("");

        setSwitches({
            retired: trode.retired
        });
        
        if(typeof resetFunc === "function") {
            resetFunc();
        }
    };

    const handleProceed = (values) => {
        const { trodeSize, serialNumber, retired } = values;

        (async () => {
            updateDisableReset(true);

            var request = {
                trodeTypeSizeId: trodeSize,
                serialNumber: serialNumber,
                retired: retired
            };
            
            if(retired === 1) {
                request.retiredDate = formatDate(Date.now());
                request.retiredByUserId = (currentUser && currentUser.sub) || "";
            }

            console.log(request);

            var response =  await alterTrodeOrCreateNew({
                uri: `/trodes/${trode.trodeId}/alter`, 
                data: request
            });

            if(response && response.trodeId) {
                updateDisplayMessage({ 
                    message: "Trode has been altered.",
                    show: true,
                    severity: "success", 
                    showAction: false 
                });

                setSavedToDb(true);
            }
            else {
                updateDisplayMessage({ 
                    message: "There was an error updating the Trode.",
                    show: true,
                    severity: "error", 
                    showAction: false 
                });
            }
        })();
    };

    const isUniqueSerialNumber = useRecoilCallback(({snapshot}) => async (serialNumber) => { 
        try {
            const results = await snapshot.getPromise(getToAPISelector(`/trode/serial-number/${serialNumber}/type/any`));
            return results;
        }
        catch(error) {
            return error;
        }    
    });

    const handleEditActivedChange = (item) => {
        setSwitches({
            ...switches,
            ...item
        });
        
        if(item.retired === 1) {
            updateIsSubmitting(true);
            updateDisplayMessage({ 
                message: "Retiring Trode cannot be undone once saved.",
                show: true,
                severity: "warning", 
                showAction: true 
            });
        }
        else {
            updateIsSubmitting(false);
            updateDisplayMessage({ 
                message: "",
                show: false,
                severity: "warning", 
                showAction: false 
            });
        }
    };

    const alterTrodeOrCreateNew = useRecoilCallback(({snapshot}) => async (req) => {
        try {
            const results = await snapshot.getPromise(postToAPISelector(req));
            return results;
        }
        catch(error) {
            return error;
        }    
    });

    const handleSubmit = (values, validateFunc, setTouched) => { 
        const { trodeSize, serialNumber } = values;
        
        (async () => {
            try {
                const valError = await validateFunc();

                if(Object.keys(valError).length > 0) {
                    setTouched(valError);
                    updateIsSubmitting(false);
                }
                else {
                    updateIsSubmitting(true);

                    var trodeWithSN = await isUniqueSerialNumber(serialNumber.trim());

                    if(Object.keys(trodeWithSN).length > 0) {
                        updateDisplayMessage({ 
                            message: "Are you sure you want to alter a Trode with an existing serial number? Click 'Reset' if this was a mistake or click 'Proceed' to continue.",
                            show: true,
                            severity: "warning", 
                            showAction: true 
                        });
                    }
                    else {
                        //set original to retired = true
                        var alterOriginalResponse = await alterTrodeOrCreateNew({  
                            uri: `/trodes/${trode.trodeId}/alter`,
                            data: {
                                retired: 1
                            }
                        });

                        if(alterOriginalResponse && alterOriginalResponse.trodeId) {  
                            //save new
                            var addNewResponse = await alterTrodeOrCreateNew({ 
                                uri: `/trodes`,
                                data: {
                                    trodeTypeId: trode.trodeTypeId,
                                    trodeTypeSizeId: trodeSize,
                                    serialNumber: serialNumber.trim(),
                                    parentId: trode.trodeId
                                }
                            });

                            if(addNewResponse && addNewResponse.trodeId) {
                                updateDisplayMessage({ 
                                    message: "The new Trode has been created and the old one has been taken out of service.",
                                    show: true,
                                    severity: "success",
                                    showAction: false 
                                });

                                setSerialNumber("");
                                setSavedToDb(true);
                                updateDisableReset(true);
                            }
                            else {
                                updateDisplayMessage({ 
                                    message: `There was an error that prevented the new Trode from being created.`,
                                    show: true,
                                    severity: "error", 
                                    showAction: false 
                                });
                            }
                        }
                        else {
                            updateDisplayMessage({ 
                                message: `There was an error taking the original Trode out of serivce. This prevented the new Trode from being created.`,
                                show: true,
                                severity: "error", 
                                showAction: false 
                            });
                        }
                    }
                }
            }
            catch(error) {
                updateDisplayMessage({ 
                    message: `There was a system error: ${error && error.message}`,
                    show: true,
                    severity: "error", 
                    showAction: false 
                });
            }
        })();
    };

    return (
        <>
            <Formik
                initialValues={{
                    trodeSize: trode.trodeTypeSizeId,
                    serialNumber: trode.serialNumber,
                    retired: switches.retired
                }}
                validationSchema={Yup.object().shape({
                    trodeSize: Yup.string().notOneOf(['0', '', 0], 'Trode Size is required').required('Trode Size is required'),
                    serialNumber: Yup.string().max(14, 'Serial number can be a max of 14 characters').required('serialNumber is required')
                })}
            >
                {({errors, handleBlur, handleChange, handleReset, validateForm, setTouched, touched, values, initialValues}) => (
                <>
                    {props.children && props.children({
                        handleClickOpen: handleClickOpen
                    })}

                    {
                        (() => {
                            initialValues.retired = switches.retired;
                        })()
                    }

                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogContent>
                            <Card>
                                <CardHeader
                                    subheader="Altering this trode will remove it from service until the new serial number is removed from service."
                                    title={trode && `Alter Trode (${trode.trodeTypeName} : ${trode.serialNumber})`}
                                />

                                <Divider />

                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Select A Size"
                                                name="trodeSize"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                required
                                                select
                                                SelectProps={{ native: true }}
                                                value={values.trodeSize}
                                                variant="outlined"
                                                error={Boolean(touched.trodeSize && errors.trodeSize)}
                                                helperText={touched.trodeSize && errors.trodeSize}
                                            >
                                                <option key={`size_0_0`} value="0">
                                                    Select Trode Size
                                                </option>

                                                {trodeSizes && trodeSizes.length > 0 && trodeSizes.map((option) => (
                                                <option key={`size_${trode.trodeId}_${option.trodeTypeSizeId}`} value={option.trodeTypeSizeId}>
                                                    {`${option.trodeTypeSize}${option.trodeTypeMeasurement}`}
                                                </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Serial Number"
                                                name="serialNumber"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                required
                                                type="text"
                                                SelectProps={{ native: true }}
                                                value={values.serialNumber || ''}
                                                variant="outlined"
                                                error={Boolean(touched.serialNumber && errors.serialNumber)}
                                                helperText={touched.serialNumber && errors.serialNumber}
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>

                                        <Grid item md={3} xs={12}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend" className={classes.formControlLabel}>
                                                    Retired
                                                </FormLabel>

                                                <FormGroup className={classes.formControlContainer}>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch 
                                                                size="small" 
                                                                checked={values.retired === 1} 
                                                                onChange={() => handleEditActivedChange({
                                                                    retired: values.retired === 1 ? 0 : 1
                                                                })} 
                                                            />
                                                        }
                                                        label={values.retired === 1 ? 'Yes' : 'No'}
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>

                                        {displayMessage.show &&
                                        <Grid item md={12} xs={12}>
                                            <Alert 
                                                severity={displayMessage.severity}
                                                action={ displayMessage.showAction &&
                                                    <Button 
                                                        variant="contained" 
                                                        color="secondary" 
                                                        size="small"
                                                        onClick={() => handleProceed(values)}
                                                    >
                                                        Proceed
                                                    </Button>
                                                }
                                            >
                                                {displayMessage.message}
                                            </Alert>
                                        </Grid>
                                        }
                                    </Grid>
                                </CardContent>

                                <Divider />

                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    p={2}
                                >
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => handleSubmit(values, validateForm, setTouched)}
                                        disabled={_isSubmitting}
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Card>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => handleCleanup(handleReset)} color="primary" disabled={disableReset}>
                                Reset
                            </Button>

                            <Button onClick={() => handleClose(handleReset)} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
                )}
            </Formik>
        </>
    );
};
