import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Container,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Page from 'src/components/Page';
import AuthCheck from 'src/components/auth-check';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useRecoilState, useRecoilCallback } from 'recoil';
import { httpClient } from 'src/lib/api-factory';
import { getUnitedStatesAtom, getCountriesAtom } from 'src/app-data/atoms/locations-atom';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "1400px",
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  card: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    color: theme.palette.text.error
  },
  reset: {
    marginRight: theme.spacing(1)
  }
}));

const AddNewCustomerView = (props) => {
  const classes = useStyles();

  const [_isSubmitting, updateIsSubmitting] = useState(false);
  const [displayMessage, updateDisplayMessage] = useState({
    message: "",
    show: false,
    severity: "", //error, warning, info, success
    showAction: false
  });

  const [unitedStates, setUnitedStates] = useRecoilState(getUnitedStatesAtom); 
  const [countries, setCountries] = useRecoilState(getCountriesAtom); 

  const getUnitedStates = useRecoilCallback(() => async () => {
    try {
      const results = await httpClient().get(`/united-states`);
        return results.data;
    }
    catch(error) {
        return [];
    }
  });

  const getCountries = useRecoilCallback(() => async () => { 
    try {
        const results = await httpClient().get("/countries");
        var countries = [];

        if(results && results.data && results.data.length > 0) {
          results.data.forEach(item => {
            if(item.countryName === 'United States of America') {
              countries.unshift(item);
            }
            else {
              countries.push(item);
            }
          });
          
          return countries;
        }
        else {
          return results.data;
        }
    }
    catch(error) {
        return [];
    }    
  });

  const handleCleanup = (resetFunc) => {
    updateIsSubmitting(false);
    updateDisplayMessage({ message: "", show: false, severity: "" });

    if (typeof resetFunc === "function") {
      resetFunc();
    }
  };

  const isUniqueCustomerNumber = useRecoilCallback(() => async (customerNumber) => {
    try {
      const results = await httpClient().get(`/customers/${customerNumber}/type/customerNumber`);
        return results.data;
    }
    catch(error) {
      return error;
    }
  });

  const addNewCustomer = useRecoilCallback(() => async (requestBody) => {
    try {
      const results = await httpClient().post(`/customers`, requestBody);
        return results.data;
    }
    catch(error) {
      return error;
    }
  });

  const handleSubmit = (values, validateFunc, setTouched, resetFunc) => {
    const { customerName, customerNumber, stateId, countryId } = values;

    (async () => {
      try {
        const valError = await validateFunc();

        if (Object.keys(valError).length > 0) {
          setTouched(valError);
          updateIsSubmitting(false);
        }
        else {
          updateIsSubmitting(true);

          var foundCustomer = await isUniqueCustomerNumber(customerNumber.trim());

          if(foundCustomer && foundCustomer.customerAccountId) {
            updateDisplayMessage({
              message: "The customer number you entered has already been used.",
              show: true,
              severity: "error",
              showAction: false
            });

            updateIsSubmitting(false);
          }
          else {
            var finalStateId = (stateId !== '' && stateId !== '0' && stateId !== 0) ? Number(stateId) : 0;

            if (countryId !== 230 && countryId !== '230') {
              finalStateId = 0;
            }

            var reqBody = {
              customerName: customerName.trim(),
              customerNumber: customerNumber.trim(),
              stateId: finalStateId,
              countryId: Number(countryId)
            };

            var postedResponse = await addNewCustomer(reqBody);

            if (postedResponse && postedResponse.id) {
              updateDisplayMessage({
                message: "The customer has been added.",
                show: true,
                severity: "success",
                showAction: false
              });

              if (typeof resetFunc === "function") {
                resetFunc();
              }
            }
            else {
              updateDisplayMessage({
                message: `There was an error adding the customer.`,
                show: true,
                severity: "error",
                showAction: false
              });
            }

            updateIsSubmitting(false);
          }
        }
      }
      catch (error) {
        updateDisplayMessage({
          message: `There was a system error: ${error && error.message}`,
          show: true,
          severity: "error",
          showAction: false
        });
      }
    })();
  };

  useEffect(() => {
    (async () => {
      try {
        const unitedStatessResponse = await getUnitedStates();
        setUnitedStates(unitedStatessResponse);

        const countriesResponse = await getCountries();
        setCountries(countriesResponse);
      }
      catch(error) {

      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthCheck>
    <Page className={classes.root} title="Add New Customer">
      <Container maxWidth={false}>
        <Typography color="textPrimary" variant="h4">
          Add New Customer
        </Typography>

        <Box mt={3}>
          <Formik
            initialValues={{
              customerName: '',
              customerNumber: '',
              stateId: 0,
              countryId: 0
            }}
            validationSchema={Yup.object().shape({
              customerName: Yup.string().max(50, 'Customer Name can be a max of 50 characters').required('Customer Name is required'),
              customerNumber: Yup.number()
                .required('Customer Number is required')
                .positive('Customer Number must be a positive number')
                .integer('Customer Number must be an integer')
                .typeError('Customer Number only accepts numbers'),
              countryId: Yup.string().notOneOf(['0', '', 0], 'Country is required').required('Country is required')
            })}
          >
            {({ errors, handleBlur, handleChange, handleReset, validateForm, setTouched, touched, values }) => (
              <>
                <Card>
                  <CardHeader
                    title={'New PulseVet Customer'}
                    subheader="All fields are required"
                  />

                  <Divider />

                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Customer Name"
                          name="customerName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          type="text"
                          SelectProps={{ native: true }}
                          value={values.customerName}
                          variant="outlined"
                          error={Boolean(touched.customerName && errors.customerName)}
                          helperText={touched.customerName && errors.customerName}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>

                      <Grid item md={3} xs={12}>
                        <TextField
                          fullWidth
                          label="Customer Number"
                          name="customerNumber"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          type="text"
                          SelectProps={{ native: true }}
                          value={values.customerNumber}
                          variant="outlined"
                          error={Boolean(touched.customerNumber && errors.customerNumber)}
                          helperText={touched.customerNumber && errors.customerNumber}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Select A Country"
                          name="countryId"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          select
                          SelectProps={{ native: true }}
                          value={values.countryId}
                          variant="outlined"
                          error={Boolean(touched.countryId && errors.countryId)}
                          helperText={touched.countryId && errors.countryId}
                          style={{ maxWidth: 500 }}
                          InputProps={{
                            classes: {
                              input: classes.listStyle
                            }
                          }}
                        >
                          <option key={`size_0_0`} value="0">
                            Select A Country
                        </option>

                          {countries && countries.length > 0 && countries.map((option) => (
                            <option key={`country_${option.countryId}`} value={option.countryId}>
                              {option.countryName}
                            </option>
                          ))}
                        </TextField>
                      </Grid>

                      {(values.countryId === 230 || values.countryId === '230') &&
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Select A State"
                            name="stateId"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            select
                            SelectProps={{ native: true }}
                            value={values.stateId}
                            variant="outlined"
                            error={Boolean(touched.stateId && errors.stateId)}
                            helperText={touched.stateId && errors.stateId}
                            style={{ maxWidth: 500 }}
                            InputProps={{
                              classes: {
                                input: classes.listStyle
                              }
                            }}
                          >
                            <option key={`size_0_0`} value="0">
                              Select A State
                            </option>

                            {unitedStates && unitedStates.length > 0 && unitedStates.map((option) => (
                              <option key={`state_${option.stateId}`} value={option.stateId}>
                                {option.stateName}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                      }

                      {displayMessage.show &&
                        <Grid item xs={12}>
                          <Alert
                            severity={displayMessage.severity}
                          >
                            {displayMessage.message}
                          </Alert>
                        </Grid>
                      }
                    </Grid>
                  </CardContent>

                  <Divider />

                  <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button 
                      color="primary" 
                      variant="contained" 
                      onClick={() => handleCleanup(handleReset)}  
                      className={classes.reset}
                    >
                      Reset
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handleSubmit(values, validateForm, setTouched, handleReset)}
                      disabled={_isSubmitting}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Box>
                </Card>
              </>
            )}
          </Formik>
        </Box>
      </Container>
    </Page>
    </AuthCheck>
  );
};

export default AddNewCustomerView;