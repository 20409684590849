import { atom } from "recoil";

export const getCustomersPartialAtom = atom({
    key: 'CUSTOMERS_PARTIAL',
    default: []
});

export const getCustomersFullAtom = atom({
    key: 'CUSTOMERS_FULL',
    default: []
});

export const getCustomersDisplayAtom = atom({
    key: 'CUSTOMERS_FULL_DISPLAY',
    default: []
});

export const customerSearchByNameKeyValueAtom = atom({
    key: 'CUSTOMERS_SEARCH_BY_NAME_KEY_VALUE',
    default: "customerSearchByName"
});