import xlsx from 'xlsx';

// https://www.npmjs.com/package/xlsx

export const exportToExcel = (data, fileName) => {
    var newWB = xlsx.utils.book_new();

    var newWS = xlsx.utils.json_to_sheet(data);

    xlsx.utils.book_append_sheet(newWB, newWS, "name");

    xlsx.writeFile(newWB, `${fileName}.xlsx`);
};


