import { atom } from "recoil";

export const loggedInUserAtom = atom({
    key: 'LOGGED_IN_USER',
    default: {
        email: '',
        avatar: '',
        groups: []
    },
    persistence_UNSTABLE: {
        type: 'LOGGED_IN_USER'
    }
});
