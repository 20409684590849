import axios from 'axios';

export const httpClient = (options) => {
    var api_url = "https://api.pulsevettt.com/v1";
    const token = localStorage.getItem("access_token");

    var headers = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        "X-Requested-With": "XMLHttpRequest"
    };

    if(options && options.headers) {
        headers = {
            ...headers,
            ...options.headers
        };
    }

    const ax = axios.create({
        baseURL: api_url,
        crossDomain: true,
        headers: headers
    });

    return ax;
};
