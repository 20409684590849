import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
    Avatar,
    Typography,
    makeStyles
} from '@material-ui/core';

import { useRecoilValue } from 'recoil';
import { loggedInUserAtom } from 'src/app-data/atoms/user-atom';

const useStyles = makeStyles(() => ({
    root: {},
    name: {
        paddingTop: "10px",
        paddingBottom: 0,
        marginBottom: 0
    }
}));

const UserAvatar = ({children, ...props}) => {
    const classes = useStyles();
    const user = useRecoilValue(loggedInUserAtom);
    const { avatarClassName, showName, variant, src } = props;

    const initials = (fn, ln) => {
        return fn?.substring(0, 1).toUpperCase() + ln?.substring(0, 1).toUpperCase();
    };

    return (
        <>
            <Avatar
                alt={`${user?.family_name}, ${user?.given_name}`}
                className={avatarClassName}
                component={RouterLink}
                to="/app/user-settings"
                src={src || user?.avatar} //here?
            >
                {`${initials(user?.given_name, user?.family_name)}`}
            </Avatar> 

            {showName &&
            <Typography 
                color="textPrimary" 
                variant={variant}
                className={classes.name}
            >
                {`${user?.given_name} ${user?.family_name}`}
            </Typography>
            }

            { children && typeof children === 'function' ?
                (children({user: user}))
                :
                (children)
            }
        </>
    );
};

UserAvatar.defaultProps = {
    showName: true,
    variant: "h5"
};

UserAvatar.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func
    ]),
    avatarClassName: PropTypes.string,
    showName: PropTypes.bool,
    variant: PropTypes.string,
    src: PropTypes.string
};

export default UserAvatar;
