import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';

import CustomerListView from 'src/views/customer/CustomerListView';
import AddNewCustomerView from 'src/views/customer/AddNewCustomerView';
import DashboardView from 'src/views/dashboard';
import LoginView from 'src/views/auth/LoginView';
import LogoutView from 'src/views/auth/LogoutView';
import NotFoundView from 'src/views/errors/NotFoundView';

//trodes
import TrodeListView from 'src/views/trodes/TrodeListView';
import TrodeCreateView from 'src/views/trodes/TrodeCreateView';
import ShippedTrodeListView from 'src/views/trodes/ShippedTrodeListView';

//reports
import CustomerHistoryListView from 'src/views/reports/CustomerHistory';
import OutstandingTrodesListView from 'src/views/reports/OutstandingTrodes';
import SerialNumberHistoryListView from 'src/views/reports/SerialNumberHistory';
import ReturnedTrodesListView from 'src/views/reports/ReturnedTrodes';
import ReturnTrodeFormView from 'src/views/reports/ReturnTrodeForm';
import ManageNotesView from 'src/views/reports/ManageNotes';

//User Settings
import UserProfileView from 'src/views/settings/UserProfileView';

//User Admin
import ManageUsersView from 'src/views/admin/ManageUsersView';


const routes = () => [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      //dashboard
      { path: 'dashboard', element: <DashboardView /> },

      //customers
      { path: 'customers', element: <CustomerListView /> },
      { path: 'customer-new', element: <AddNewCustomerView /> },

      //trodes
      { path: 'available-trodes', element: <TrodeListView /> },
      { path: 'trodes-create', element: <TrodeCreateView /> },
      { path: 'shipped-trodes', element: <ShippedTrodeListView /> },

      //reports
      { path: 'customer-history', element: <CustomerHistoryListView /> },
      { path: 'outstanding-trodes', element: <OutstandingTrodesListView /> },
      { path: 'serial-number-history', element: <SerialNumberHistoryListView /> },
      { path: 'returned-trodes', element: <ReturnedTrodesListView /> },
      { path: 'return-trode-form', element: <ReturnTrodeFormView /> },
      { path: 'manage-notes', element: <ManageNotesView /> },

      //settings
      { path: 'user-settings', element: <UserProfileView /> },

      //admin
      { path: 'manage-users', element: <ManageUsersView /> },

      //404
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'logout', element: <LogoutView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
