import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles
}
from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    root: {},
    title: { //not used yet
        fontSize: "0.850rem"
    },
    content: {
        marginBottom: theme.spacing(4)
    },
}));


const MessageBox = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => setOpen(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {props.children && props.children({
                handleClickOpen: handleClickOpen
            })}

            <Dialog
                open={props.open || open}
                onClose={props.handleClose || handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Alert severity={props.severity} >
                        {props.title}
                    </Alert>
                </DialogTitle>

                <DialogContent className={classes.content}>
                    <DialogContentText id="alert-dialog-description">
                        {props.message}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={props.handleClose || handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default MessageBox;