import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';

import { ShipTrodeModal } from './ShipTrodeModal';
import { AlterTrodeModal } from './AlterTrodeModal';
import Pagination from '@material-ui/lab/Pagination';

import { availableTrodesAtom } from '../../../app-data/atoms/trodes-atom';
import { useRecoilValue } from 'recoil';

const useStyles = makeStyles((theme) => ({
  root: {},
  actionLink: {
    fontSize: "0.875rem",
    color: "#3f51b5",
    padding: "0",
    textTransform: 'none'
  },
  paginationCount: {
    //paddingRight: theme.spacing(2),
    padding: "5px 10px 0 0"
  }
}));

/* 
 When a trode is retired it is removed from (full) list and the list is refreshed which puts it back to page 1
 but still shows the current page you were on. If you click on a page next to the one you are on then 
 back you will be back where you should be so setPage needs to be called from the alter modal with the 
 current page.
*/

const Results = ({ className, showLoading, ...rest }) => {
  const classes = useStyles();
  const [page, setPage] = useState(1); 
  const [pageCount, setPageCount] = useState(0); 
  const [currentSet, setCurrentSet] = useState([]);
  const trodes = useRecoilValue(availableTrodesAtom);
  const pageItemSize = 100;
  
  const getCurrentSet = (value, dataArray) => {
    var _currentSet = [];
    var _to = value * pageItemSize;
    var _from = _to - pageItemSize;

    for(let i = _from; i < _to; i++) {
        if(typeof dataArray[i] !== 'undefined') {
            _currentSet.push(dataArray[i]);
        }
    }
    
    setCurrentSet(_currentSet);
  };

  const handlePageChange = (event, value) => { 
    setPage(value);
    getCurrentSet(value, trodes);
  };

  useEffect(() => { 
    (async () => {
        try {
            getCurrentSet(1, trodes); 
            setPageCount(Math.ceil(trodes.length / pageItemSize));
        }
        catch(error) {

        }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trodes]);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Ship</TableCell>
                <TableCell>Alter</TableCell>
                <TableCell>Trode</TableCell>
                <TableCell>Serial Number</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              { showLoading && currentSet.length === 0 && (
                <TableRow>
                  <TableCell colSpan="5" style={{textAlign: "center"}}>
                    <CircularProgress /> Loading Data...
                  </TableCell>
                </TableRow>
              )}

              {currentSet && currentSet.map((trode) => (
                <TableRow
                  hover
                  key={trode.trodeId}
                >
                  <TableCell>
                    <Box
                      alignItems="center"
                      display="flex"
                    >
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        <ShipTrodeModal key={`ship_${trode.trodeId}`} trode={trode}>
                          {
                            ({handleClickOpen}) => (
                              <Button variant="text" className={classes.actionLink} onClick={handleClickOpen}>
                                Ship Trode
                              </Button>
                            )
                          }
                        </ShipTrodeModal>
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell>
                    <Box
                      alignItems="center"
                      display="flex"
                    >
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        <AlterTrodeModal key={`alter_${trode.trodeId}`} trode={trode}>
                          {
                            ({handleClickOpen}) => (
                              <Button variant="text" className={classes.actionLink} onClick={handleClickOpen}>
                                Alter Trode
                              </Button>
                            )
                          }
                        </AlterTrodeModal>
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell>
                    {trode.trodeTypeName}
                  </TableCell>

                  <TableCell>
                    {trode.serialNumber}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      
      

      <Grid container>
        <Grid item xs={6}>
          <Pagination count={pageCount} page={page} onChange={handlePageChange} /> 
        </Grid>

        <Grid item xs={6} container justify="flex-end" className={classes.paginationCount}>
          <Typography color="textPrimary" variant="body1" style={{fontSize: "0.850rem"}}>
            Trodes Found: {trodes && trodes.length}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
