import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye';

import { reportReturnTrodeFormAtom } from 'src/app-data/atoms/reports-atom';
import { useRecoilValue } from 'recoil';

const useStyles = makeStyles((theme) => ({
  root: {},
  formUnderlineSection: {
    borderBottom: "1px solid #999999"
  },
  formFont: {
    fontSize: "0.850rem"
  },
  formHeaderContainer: {
    paddingTop: theme.spacing(3)
  },
  calloutColumn: {
    paddingLeft: theme.spacing(3),
    maxWidth: "130px",
    borderBottom: "1px solid #999999"
  },
  selection: {
    paddingRight: theme.spacing(3)
  }
}));

const Results = ({ className, componentToPrintRef, ...rest }) => {
  const classes = useStyles();
  const reportData = useRecoilValue(reportReturnTrodeFormAtom);

  return (Object.keys(reportData).length > 0 &&
    <Card
      className={clsx(classes.root, className)}
      {...rest}
      ref={componentToPrintRef}
    >
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan="5" className={classes.formHeaderContainer}>
                <img
                  alt="Logo"
                  src="https://cdn.pulsevettt.com/logos/logo_250x81.png"
                  style={{width: "15%", height: "%0%", filter: "brightness(0) invert(0)"}}
                />
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {reportData && (
              <>
              <TableRow>
                <TableCell colSpan="5" className={classes.calloutColumn}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    <b>TRODE RETURN FORM</b>
                    <br />
                    Upon receiving your refurbished trode, complete this form and include it with the return 
                    shipment of your used trode. The expended trode should be returned in the box that the 
                    refurbished trode was received in. A preprinted FedEx return label is included for your 
                    convenience.  Note: Returning a used trode will not automatically trigger a refurbished 
                    trode order. If you need to place an order for a refurbished trode, please contact PulseVet 
                    at 800-245-4417 or <span style={{color: "#0000ff"}}>orders@pulsevet.com</span>. 
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="1" className={classes.calloutColumn}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    SERIAL #:
                  </Typography>
                </TableCell>

                <TableCell colSpan="4" className={classes.formUnderlineSection}>
                  &nbsp;
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="1" className={classes.calloutColumn}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    FACILITY:
                  </Typography>
                </TableCell>

                <TableCell colSpan="4" className={classes.formUnderlineSection}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    {`(${reportData.customerNumber}) ${reportData.customerName}`}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="1" className={classes.calloutColumn}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    ADDRESS:
                  </Typography>
                </TableCell>

                <TableCell colSpan="4" className={classes.formUnderlineSection}>
                  &nbsp;
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="1" className={classes.calloutColumn}>
                <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    LOCATION:
                  </Typography>
                </TableCell>

                <TableCell colSpan="4" className={classes.formUnderlineSection}>
                  <span style={{paddingRight: "300px"}}><b>City:</b></span>
                  <span style={{paddingRight: "200px"}}><b>State:</b> {reportData.stateName && reportData.stateName !== '' ? `${reportData.stateName}` : ''}</span>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="1" className={classes.calloutColumn}>
                  &nbsp;
                </TableCell>

                <TableCell colSpan="4" className={classes.formUnderlineSection}>
                  <span style={{paddingRight: "300px"}}><b>Zip:</b></span>
                  <span><b>Country:</b> {reportData.stateName && reportData.stateName !== '' ? 'US' : reportData.countryName}</span>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="1" className={classes.calloutColumn}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    CONTACT:
                  </Typography>
                </TableCell>

                <TableCell colSpan="4" className={classes.formUnderlineSection}>
                  &nbsp;
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="1" className={classes.calloutColumn}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    PHONE:
                  </Typography>
                </TableCell>

                <TableCell colSpan="4" className={classes.formUnderlineSection}>
                  &nbsp;
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="1" className={classes.calloutColumn}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    TRODE:
                  </Typography>
                </TableCell>

                <TableCell colSpan="1" className={classes.formUnderlineSection} style={{maxWidth: "100px"}}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    VersaTron: 
                  </Typography>
                </TableCell>

                <TableCell colSpan="3" className={classes.formUnderlineSection}>
                  <span className={classes.selection}><CheckBoxOutlineBlankIcon /> 5mm</span>
                  <span className={classes.selection}><CheckBoxOutlineBlankIcon /> 20mm</span>
                  <span className={classes.selection}><CheckBoxOutlineBlankIcon /> 35mm</span>
                  <span className={classes.selection}><CheckBoxOutlineBlankIcon /> 80mm</span>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="1" className={classes.calloutColumn}>
                  &nbsp;
                </TableCell>

                <TableCell colSpan="1" className={classes.formUnderlineSection} style={{maxWidth: "100px"}}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    VersaTron/ProPulse: 
                  </Typography>
                </TableCell>

                <TableCell colSpan="3" className={classes.formUnderlineSection}>
                  <span className={classes.selection}><CheckBoxOutlineBlankIcon /> 5mm</span>
                  <span className={classes.selection}><CheckBoxOutlineBlankIcon /> 20mm</span>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="1" className={classes.calloutColumn}>
                  &nbsp;
                </TableCell>

                <TableCell colSpan="1" className={classes.formUnderlineSection} style={{maxWidth: "100px"}}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    X-Trode: 
                  </Typography>
                </TableCell>

                <TableCell colSpan="3" className={classes.formUnderlineSection}>
                  <span className={classes.selection}><CheckBoxOutlineBlankIcon /> Shallow</span>
                  <span className={classes.selection}><CheckBoxOutlineBlankIcon /> Deep</span>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="5" className={classes.calloutColumn}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    <b>Reason for Trode Return:</b>
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="5" className={classes.calloutColumn}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    <span className={classes.selection}><CheckBoxOutlineBlankIcon /> No pulses/shocks remaining</span>
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="5" className={classes.calloutColumn}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    <span className={classes.selection}><CheckBoxOutlineBlankIcon />
                      Trode is past expiration AND deflation has made it unusable. [Note: PulseVet ensures trode function during 
                      a 6-month period. Trodes may be used beyond a 6 month period if shocks remain and the trode shows no sign 
                      of malfunction.]
                    </span>
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="5" className={classes.calloutColumn}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    <span className={classes.selection}><CheckBoxOutlineBlankIcon />
                      Damaged/Faulty: Please complete the following: 
                    </span>
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="5" className={classes.calloutColumn} style={{paddingLeft: "80px"}}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    <span className={classes.selection}><PanoramaFishEyeIcon />
                      Explanation of problem experienced: 
                    </span>
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="5" className={classes.calloutColumn}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    &nbsp;
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="5" className={classes.calloutColumn} style={{paddingLeft: "80px"}}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    <span className={classes.selection}><PanoramaFishEyeIcon />
                      Error code displayed: 
                    </span>
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="5" className={classes.calloutColumn} style={{paddingLeft: "80px"}}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    <span className={classes.selection}><PanoramaFishEyeIcon />
                      Is this event associated with an injury to client, user, or bystander during operation? If yes, explain: 
                    </span>
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="5" className={classes.calloutColumn}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    &nbsp;
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="5" className={classes.calloutColumn} style={{paddingLeft: "80px"}}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    <span className={classes.selection}><PanoramaFishEyeIcon />
                      Did this event prevent or interrupt a client treatment once sedation or anesthesia had been administered? If yes, explain:
                    </span>
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="5" className={classes.calloutColumn}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    &nbsp;
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="5" className={classes.calloutColumn} style={{paddingLeft: "80px"}}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    <span className={classes.selection}><PanoramaFishEyeIcon />
                      Additional comments: (If the space provided is insufficient to describe the event, please attach an additional page to this report.) 
                    </span>
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="5" className={classes.calloutColumn}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    &nbsp;
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="5" className={classes.calloutColumn}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    &nbsp;
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="5" className={classes.calloutColumn} style={{paddingLeft: "80px"}}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    <span className={classes.selection}><PanoramaFishEyeIcon />
                      Was a service call also requested? If yes, date? 
                    </span>
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="5" className={classes.formUnderlineSection} style={{backgroundColor: "#eeeeee"}}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    Signature of contact completing this form:
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="5" className={classes.formUnderlineSection} style={{backgroundColor: "#eeeeee"}}>
                  <Typography color="textPrimary" variant="body1" className={classes.formFont}>
                    Date:
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan="5">
                  <Typography color="textPrimary" variant="body1" className={classes.formFont} style={{textAlign: "center"}}>
                    11390 Old Roswell Road, Suite 120 Alpharetta, GA 30009<br />
                    678.987.5100 (p) • 1.800.245.4417 (toll-free U.S. phone) • +1 678.987.5100 (international phone)<br />
                    678.987.5101 (f) • 1.800.246.2895 (toll-free U.S. fax) • +1 678.987.5101 (international fax)<br />
                    www.pulsevet.com
                  </Typography>
                </TableCell>
              </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
