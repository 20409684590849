import { atom } from "recoil";

export const reportCustomerHistoryAtom = atom({
    key: 'REPORT_CUSTOMER_HISTORY',
    default: []
});

export const reportOutstandingTrodesAtom = atom({
    key: 'REPORT_OUTSTANDING_TRODES',
    default: []
});

export const reportSerialNumberHistoryAtom = atom({
    key: 'REPORT_SERIAL_NUMBER_HISTORY',
    default: []
});

export const reportReturnedTrodesAtom = atom({
    key: 'REPORT_RETURNED_TRODES',
    default: []
});

export const reportReturnTrodeFormAtom = atom({
    key: 'REPORT_RETURN_TRODE_FORM',
    default: {}
});