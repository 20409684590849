import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  Popover,
  Button,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

import Pagination from '@material-ui/lab/Pagination';

import { reportCustomerHistoryAtom } from 'src/app-data/atoms/reports-atom';
import { useRecoilValue } from 'recoil';
import {v4 as uuid} from 'uuid';
import { formatDate } from  'src/lib/date-functions';

const useStyles = makeStyles((theme) => ({
  root: {},
  actionLink: {
    fontSize: "0.875rem",
    color: "#3f51b5",
    padding: "0",
    textTransform: 'none'
  },
  paginationCount: {
    //paddingRight: theme.spacing(2),
    padding: "5px 10px 0 0"
  }
}));

const Results = ({ className, showLoading, ...rest }) => {
  const classes = useStyles();
  const [page, setPage] = useState(1); 
  const [pageCount, setPageCount] = useState(0); 
  const [currentSet, setCurrentSet] = useState([]);
  const reportData = useRecoilValue(reportCustomerHistoryAtom);
  const pageItemSize = 100;

  //popover
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const getCurrentSet = (value, dataArray) => {
    var _currentSet = [];
    var _to = value * pageItemSize;
    var _from = _to - pageItemSize;

    for(let i = _from; i < _to; i++) {
        if(typeof dataArray[i] !== 'undefined') {
            _currentSet.push(dataArray[i]);
        }
    }
    
    setCurrentSet(_currentSet);
  };

  const handlePageChange = (event, value) => { 
    setPage(value);
    getCurrentSet(value, reportData);
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };
  const handleFilterChange = (event) => {
    const { value } = event.target;

    if(value) {
      var filtered = reportData.filter(x => x.customerName.toLowerCase().startsWith(value.toLowerCase()));
      setCurrentSet(filtered);
      setPageCount(Math.ceil(filtered.length / pageItemSize));
    }
    else {
      getCurrentSet(page, reportData);
      setPageCount(Math.ceil(reportData.length / pageItemSize));
    }
  };

  const handleClearFilter = () => { 
    getCurrentSet(page, reportData);
    setPageCount(Math.ceil(reportData.length / pageItemSize));
  };

  useEffect(() => { 
    (async () => {
        try {
            getCurrentSet(1, reportData);
            setPageCount(Math.ceil(reportData.length / pageItemSize));
        }
        catch(error) {

        }
    })();
  }, [reportData]);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan="8">
                  <Button aria-describedby={id} onClick={handleFilterClick} disabled={currentSet.length <= 0}>
                    Filter
                  </Button>

                  <Button onClick={handleClearFilter} disabled={currentSet.length <= 0}>
                    Clear Filter
                  </Button>

                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleFilterClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Card>
                      <CardContent>
                        <Box maxWidth={500}>
                          <Typography color="textPrimary" variant="body1" style={{fontSize: "0.850rem"}}>
                            Customer Name
                          </Typography>
                        </Box>

                        <Box maxWidth={500} style={{paddingTop: "10px"}}>
                          <TextField
                            autoFocus
                            fullWidth
                            type="search"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SvgIcon
                                    fontSize="small"
                                    color="action"
                                  >
                                    <SearchIcon />
                                  </SvgIcon>
                                </InputAdornment>
                              )
                            }}
                            placeholder="Customer Name"
                            variant="outlined"
                            onChange={handleFilterChange}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Popover>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Customer #</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Country</TableCell>
                <TableCell>Serial #</TableCell>
                <TableCell>Trode</TableCell>
                <TableCell>Date Shipped</TableCell>
                <TableCell>Date Received</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              { showLoading && currentSet.length === 0 && (
                <TableRow>
                  <TableCell colSpan="9" style={{textAlign: "center"}}>
                    <CircularProgress /> Loading Data...
                  </TableCell>
                </TableRow>
              )}

              {currentSet && currentSet.map((item) => (
                <TableRow hover key={`row_data_${item.serialNumber}_${uuid()}`}>
                  <TableCell>
                    {item.customerNumber}
                  </TableCell>

                  <TableCell>
                    {item.customerName}
                  </TableCell>

                  <TableCell>
                    {item.stateName}
                  </TableCell>

                  <TableCell>
                    {item.countryName === 'United States of America' ? 'United States' : item.countryName}
                  </TableCell>

                  <TableCell>
                    {item.serialNumber}
                  </TableCell>

                  <TableCell>
                    {`${item.trodeTypeName} ${item.trodeTypeSize}${item.trodeTypeMeasurement}`}
                  </TableCell>

                  <TableCell>
                    {formatDate(item.dateSentOut)}
                  </TableCell>

                  <TableCell>
                    {item.dateReceivedIn ? formatDate(item.dateReceivedIn) : 'Out with customer'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      
      

      <Grid container>
        <Grid item xs={6}>
          <Pagination count={pageCount} page={page} onChange={handlePageChange} /> 
        </Grid>

        <Grid item xs={6} container justify="flex-end" className={classes.paginationCount}>
          <Typography color="textPrimary" variant="body1" style={{fontSize: "0.850rem"}}>
            Items Found: {reportData && reportData.length}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
