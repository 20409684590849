import { atom } from "recoil";

export const trodeSearchSerialNumberAtom = atom({
    key: 'TRODE_SEARCH_SERIAL_NUMBER',
    default: ""
});

export const availableTrodesAtom = atom({
    key: 'AVAILABLE_TRODES',
    default: []
});

export const availableTrodeTypesAtom = atom({
    key: 'TRODE_TYPES',
    default: []
});

export const availableTrodeSizesAtom = atom({
    key: 'TRODE_SIZES',
    default: []
});

export const shippedTrodesAtom = atom({
    key: 'SHIPPED_TRODES',
    default: []
});
