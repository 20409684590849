import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Container,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Page from 'src/components/Page';
import AuthCheck from 'src/components/auth-check';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useRecoilCallback } from 'recoil';
import { httpClient } from 'src/lib/api-factory';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "1400px",
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  card: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    color: theme.palette.text.error
  },
  reset: {
    marginRight: theme.spacing(1)
  },
  textField: {
    width: 350
  },
  readOnlyTextArea: {
    backgroundColor: "#eee"
  }
}));

const ManageNotesView = (props) => {
  const classes = useStyles();

  const [_isSubmitting, updateIsSubmitting] = useState(false);
  const [displayMessage, updateDisplayMessage] = useState({
    message: "",
    show: false,
    severity: "", //error, warning, info, success
    showAction: false
  });
  const [trode, setTrode] = useState({});

  const getTrodeBySerialNumber = useRecoilCallback(() => async (serialNumber) => { 
    try {
        const results = await httpClient().get(`/trode/serial-number/${serialNumber}/type/all`); //available
        return results.data;
    }
    catch(error) {
        return error;
    }    
  });

  const getFromDb = useRecoilCallback(() => async (uri) => { 
    try {
      const response = await httpClient().get(uri);
      return response.data;
    }
    catch(error) {
      return error;
    }    
  });

  const postToDb = useRecoilCallback(() => async (uri, data) => { 
    try {
      const response = await httpClient().post(uri, data);
      return response.data;
    }
    catch(error) {
      return error;
    }    
  }); 

  const handleResetHandler = (resetFunc) => {
    updateIsSubmitting(false);
    updateDisplayMessage({ message: "", show: false, severity: "" });
    setTrode({});

    resetFunc?.();
  };

  const handleCleanupAfterSubmit = (resetFunc) => {
    updateIsSubmitting(false);
    setTrode({});
    resetFunc?.();
  };

  const checkSerialNumberLengthHandler = (e) => {
    e.target.value = e.target.value.toString().slice(0, 14);
  };

  const searchHandler = (values, validateFunc, setTouched) => {
    const { serialNumber } = values;

    (async () => {
      try {
        const valError = await validateFunc();

        if(valError && valError.hasOwnProperty('serialNumber')) { 
          setTouched({
            serialNumber: true
          });
          updateIsSubmitting(false);
        }
        else {
          updateIsSubmitting(true);
          
          const results = await getTrodeBySerialNumber(serialNumber);

          if(results && results.trodeId) {
            setTrode(results);
            updateDisplayMessage({ show: false });
          }
          else {
            updateDisplayMessage({
              message: "No records were found. Revise your search criteria and try searching again.",
              show: true,
              severity: "warning",
              showAction: false
            });
          }
          updateIsSubmitting(false);
        }
      }
      catch(error) {

      }
    })();
  };

  const handleSubmit = (values, validateFunc, setTouched, resetFunc) => {
    const { notes } = values;

    (async () => {
      try {
        const valError = await validateFunc();

        if(valError && valError.hasOwnProperty('notes')) { 
          setTouched({
            notes: true
          });
          updateIsSubmitting(false);
        }
        else {
          updateIsSubmitting(true);

          if (notes && notes.trim() !== '') {
            if (trode.parentId) {
              var parent = await getFromDb(`/trodes/${trode.parentId}`);

              var _notes;
              if (parent.notes) {
                _notes = parent.notes;
              }

              _notes = _notes ? _notes.concat(`\n`, notes.trim()) : notes.trim();

              if (parent && parent.trodeId) {
                //get parent notes, concat new note into parent note
                await postToDb(`/trodes/${parent.trodeId}/alter`, {
                  notes: btoa(_notes)
                });
              }
            }
          }

          var allNotes;
          if(trode.notes) {
              allNotes = trode.notes;
          }

          if(notes && notes.trim() !== '') {
              allNotes = allNotes ?  allNotes.concat(`\n`, notes.trim()) : notes.trim();
          }

          var updateTrodeResponse = await postToDb(`/trodes/${trode.trodeId}/alter`, {
              notes: btoa(allNotes)
          });

          if (updateTrodeResponse && updateTrodeResponse.trodeId) {
            updateDisplayMessage({
              message: "The note has been saved to the Trode.",
              show: true,
              severity: "success"
            });

            handleCleanupAfterSubmit(resetFunc);
          }
          else {
            updateIsSubmitting(false);

            updateDisplayMessage({
              message: `There was an error saving the note to the Trode`,
              show: true,
              severity: "error"
            });
          }
        }
      }
      catch (error) {
        updateDisplayMessage({
          message: `There was a system error: ${error && error.message}`,
          show: true,
          severity: "error",
          showAction: false
        });
      }
    })();
  };

  return (
    <AuthCheck>
    <Page className={classes.root} title="Manage Notes">
      <Container maxWidth={false}>
        <Typography color="textPrimary" variant="h4">
          Manage Notes
        </Typography>

        <Box mt={3}>
          <Formik
            initialValues={{
              serialNumber: '',
              notes: ''
            }}
            validationSchema={Yup.object().shape({
              serialNumber: Yup.string().max(14, 'Serial number can be a max of 14 characters').required('serialNumber is required'),
              notes: Yup.string().required('Notes are required')
            })}
          >
            {({ errors, handleBlur, handleChange, handleReset, validateForm, setTouched, touched, values }) => (
              <>
                <Card>
                  <CardHeader
                    title={'Manage Customer Notes'}
                    subheader="All fields are required"
                  />

                  <Divider />

                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          label="Serial Number"
                          name="serialNumber"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          type="text"
                          SelectProps={{ native: true }}
                          value={values.serialNumber || ''}
                          variant="outlined"
                          error={Boolean(touched.serialNumber && errors.serialNumber)}
                          helperText={touched.serialNumber && errors.serialNumber}
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{
                            endAdornment: (
                              <Button
                                variant="contained"
                                onClick={() => searchHandler(values, validateForm, setTouched)}
                                disabled={_isSubmitting}
                              >
                                Search
                              </Button>
                            )
                          }}
                          onInput = {checkSerialNumberLengthHandler} 
                        />
                      </Grid>

                      { trode && Object.keys(trode).length > 0 &&
                      <Grid item xs={12}>
                        <Typography color="textPrimary" variant="body1" style={{fontSize: "0.850rem"}}>
                          {`Trode: ${trode.trodeTypeName} ${trode.serialNumber}`}
                        </Typography>
                      </Grid>
                      }

                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="All Trode Notes"
                          name="all_notes"
                          multiline
                          rows={10}
                          rowsMax={10}
                          readOnly
                          SelectProps={{ native: true }}
                          value={trode && ((trode.notes && atob(trode.notes)) || '')}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true
                          }}
                          className={classes.readOnlyTextArea}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Add New Note"
                          name="notes"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          multiline
                          rows={5}
                          rowsMax={5}
                          SelectProps={{ native: true }}
                          value={atob(values.notes)}
                          variant="outlined"
                          error={Boolean(touched.notes && errors.notes)}
                          helperText={touched.notes && errors.notes}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>

                      {displayMessage.show &&
                        <Grid item xs={12}>
                          <Alert severity={displayMessage.severity}>
                            {displayMessage.message}
                          </Alert>
                        </Grid>
                      }
                    </Grid>
                  </CardContent>

                  <Divider />

                  <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button onClick={() => handleResetHandler(handleReset)} color="primary" className={classes.reset}>
                        Reset
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handleSubmit(values, validateForm, setTouched, handleReset)}
                      disabled={_isSubmitting}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Box>
                </Card>
              </>
            )}
          </Formik>
        </Box>
      </Container>
    </Page>
    </AuthCheck>
  );
};

export default ManageNotesView;