import React, { useState } from 'react';
import { 
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    makeStyles,
    FormControlLabel,
    Switch
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { useRecoilCallback, useRecoilState } from 'recoil';
import { availableTrodeSizesAtom } from 'src/app-data/atoms/trodes-atom';
import { postToAPISelector } from 'src/app-data/triggers/api-triggers';
import { httpClient } from 'src/lib/api-factory';

import MessageBox from 'src/components/MessageBox';


const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        fontSize: "0.850rem"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    reset: {
        marginRight: theme.spacing(1)
    },
    actionLink: {
        fontSize: "0.875rem",
        color: "#3f51b5",
        padding: "0",
        textTransform: 'none'
    },
    inactiveWarning: {
        fontSize: "0.775rem",
        color: "#ff0000",
        width: "auto"
    }
}));

export const NewTrodeSize = (props) => { 
    const classes = useStyles();
    const [displayMessage, updateDisplayMessage] = useState({
        message: "",
        show: false,
        severity: "", //error, warning, info, success
        showAction: false
    });
    const [_isSubmitting, updateIsSubmitting] = useState(false);
    const [allTrodeSizes, addNewItemToState] = useRecoilState(availableTrodeSizesAtom);
    const [sizeToEdit, setSizeToEdit] = useState({});
    const [deactivated, setDeactivated] = useState(false);
    const [alert, setAlert] = useState({
        severity: "warning",
        title: "",
        message: "",
        open: false
    });

    const isUnique = useRecoilCallback(() => async (size, measurement) => { 
        try {
            const results = await httpClient().get(`/trode-sizes/size/${size}/measurement/${measurement}`);
            return results.data;
        }
        catch(error) {
            return error;
        }    
    });

    const postToAPI = useRecoilCallback(({snapshot}) => async (req) => {
        try {
            const results = await snapshot.getPromise(postToAPISelector(req));
            return results;
        }
        catch(error) {
            return error;
        }    
    });

    const refreshTrodeSizeList = async () => {
        const results = await httpClient().get(`/trode-sizes`);
        addNewItemToState(results.data);
    };

    const handleCleanup = (resetFunc) => {
        updateIsSubmitting(false);
        updateDisplayMessage({ message: "", show: false, severity: "" });
        
        if(typeof resetFunc === "function") {
            resetFunc();
        }
    };

    const handleEditOrCancel = (item, resetFunc) => {
        setSizeToEdit(item);
        setDeactivated(false);

        if(typeof resetFunc === "function") {
            resetFunc();
        }
    };

    const handleEditActivedChange = (item, value) => {
        setSizeToEdit({...item, isActive: value === 1 ? 0 : 1})
        setDeactivated(!deactivated);

        var which = value === 1 ? 0 : 1;

        if(which === 0) {
            setAlert({
                ...alert,
                open: true,
                title: "Important Message",
                message: "Once set to 'Inactive' and saved, the Trode Size will be removed. This cannot be undone. Press the Cancel button if you did not mean to do this.",
                severity: "warning"
            });
        }
    };

    const handleEditSave = (values, validateFunc, setTouched, resetFunc) => {
        const { edit_trodeTypeSize, edit_trodeTypeMeasurement, edit_isActive } = values;

        (async () => { 
            try {
                const valError = await validateFunc();
                var editErrors = false;

                Object.keys(valError).forEach(key => {
                    if(key.indexOf('edit_') !== -1) {
                        editErrors = true;

                        setTouched({
                            [key]: true
                        });
                    }
                }); 

                if(!editErrors) { 
                    var alterResponse = await postToAPI({ 
                        uri: `/trode-sizes/${sizeToEdit.trodeTypeSizeId}/alter`,
                        data: {
                            trodeTypeSize: Number(edit_trodeTypeSize.toString().trim()),
                            trodeTypeMeasurement: edit_trodeTypeMeasurement.trim(),
                            isActive: edit_isActive
                        }
                    });

                    if(alterResponse && alterResponse.trodeTypeSizeId) {
                        await refreshTrodeSizeList();
                    }
                    else {
                        setAlert({
                            ...alert,
                            open: true,
                            title: "Error Message",
                            message: "There was an error and the system failed to make the update.",
                            severity: "error"
                        });
                    }

                    handleEditOrCancel({}, resetFunc);
                }
            }
            catch(error) {
                console.log(error);
                setAlert({
                    ...alert,
                    open: true,
                    title: "Error Message",
                    message: "There was an error and the system failed to make the update.",
                    severity: "error"
                });
            }
        })();
    };

    const handleSubmit = (values, validateFunc, setTouched, resetFunc) => { 
        const { trodeTypeSize, trodeTypeMeasurement } = values;

        (async () => {
            try {
                const valError = await validateFunc();
                var entryErrors = false;

                Object.keys(valError).forEach(key => {
                    if(key.indexOf('edit_') === -1) {
                        entryErrors = true;

                        setTouched({
                            [key]: true
                        });
                    }
                });

                if(entryErrors) {
                    updateIsSubmitting(false);
                }
                else {
                    updateIsSubmitting(true);

                    var foundItem = await isUnique(trodeTypeSize, trodeTypeMeasurement);

                    if(Object.keys(foundItem).length > 0) {
                        updateDisplayMessage({ 
                            message: "The Trode Size you entered is already in use. Please enter a new size.",
                            show: true,
                            severity: "warning", 
                            showAction: false 
                        });

                        updateIsSubmitting(false);
                    }
                    else {
                        updateIsSubmitting(true);

                        var addNewResponse = await postToAPI({ 
                            uri: `/trode-sizes`,
                            data: {
                                trodeTypeSize: Number(trodeTypeSize.toString().trim()),
                                trodeTypeMeasurement: trodeTypeMeasurement.trim()
                            }
                        });

                        if(addNewResponse && addNewResponse.trodeTypeSizeId) {
                            await refreshTrodeSizeList();

                            updateDisplayMessage({ 
                                message: "The new Trode Size has been created.",
                                show: true,
                                severity: "success",
                                showAction: false 
                            });
                    
                            if(typeof resetFunc === "function") {
                                resetFunc();
                            }
                        }
                        else {
                            updateDisplayMessage({ 
                                message: `There was an error creating the new Trode Size. Take note of the selections you've made and contact an administrator.`,
                                show: true,
                                severity: "error", 
                                showAction: false 
                            });
                        }
                    }
                }
            }
            catch(error) {
                updateDisplayMessage({ 
                    message: `There was a system error: ${error && error.message}`,
                    show: true,
                    severity: "error", 
                    showAction: false 
                });
            }
        })();
    };

    return (
        <>
            <Formik
                initialValues={{
                    trodeTypeSize: '',
                    trodeTypeMeasurement: 'mm',
                    edit_trodeTypeSize: '',
                    edit_trodeTypeMeasurement: 'mm',
                    edit_isActive: sizeToEdit.isActive || 0
                }}
                validationSchema={Yup.object().shape({
                    trodeTypeSize: Yup.number()
                        .max(1000, 'Trode size can have a max value of 1000')
                        .required('Trode size is required')
                        .positive('Trode size must be a positive number')
                        .integer('Trode size must be an integer')
                        .typeError('Trode size only accepts numbers'),
                    edit_trodeTypeSize: sizeToEdit.trodeTypeSize && 
                        Yup.number()
                            .max(1000, 'Trode size can have a max value of 1000')
                            .required('Trode size is required')
                            .positive('Trode size must be a positive number')
                            .integer('Trode size must be an integer')
                            .typeError('Trode size only accepts numbers')
                })}
            >
                {({errors, handleBlur, handleChange, handleReset, validateForm, setTouched, touched, values, initialValues}) => (
                <>
                    <Card>
                        <CardHeader
                            subheader="All fields are required."
                            title="Create New Trode Size"
                        />

                        <Divider />

                        <CardContent>
                            <Grid container spacing={10}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Trode Size"
                                        name="trodeTypeSize"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        required
                                        type="text"
                                        SelectProps={{ native: true }}
                                        value={values.trodeTypeSize || ''}
                                        variant="outlined"
                                        error={Boolean(touched.trodeTypeSize && errors.trodeTypeSize)}
                                        helperText={touched.trodeTypeSize && errors.trodeTypeSize}
                                        className={classes.textField}
                                        style={{width: "200px"}}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />

                                    <TextField
                                        fullWidth
                                        label="Measurement"
                                        name="trodeTypeMeasurement"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        required
                                        type="text"
                                        SelectProps={{ native: true }}
                                        value={values.trodeTypeMeasurement || ''}
                                        variant="outlined"
                                        error={Boolean(touched.trodeTypeMeasurement && errors.trodeTypeMeasurement)}
                                        helperText={touched.trodeTypeMeasurement && errors.trodeTypeMeasurement}
                                        className={classes.textField}
                                        style={{width: "200px"}}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        disabled
                                    />
                                </Grid>

                                {displayMessage.show &&
                                <Grid item xs={12}>
                                    <Alert severity={displayMessage.severity}>
                                        {displayMessage.message}
                                    </Alert>
                                </Grid>
                                }
                            </Grid>
                        </CardContent>

                        <Divider />

                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}
                        >
                            <Button 
                                color="primary" 
                                variant="contained"
                                onClick={() => handleCleanup(handleReset)} 
                                className={classes.reset}
                            >
                                Reset
                            </Button>

                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                onClick={() => handleSubmit(values, validateForm, setTouched, handleReset)}
                                disabled={_isSubmitting}
                            >
                                Submit
                            </Button>
                        </Box>

                        <Divider />

                        <PerfectScrollbar>
                            <Box>
                                <Table>
                                    <TableHead>
                                        <TableRow style={{backgroundColor: "#dedede"}}>
                                            <TableCell>Type Size</TableCell>
                                            <TableCell>Measurement</TableCell>
                                            <TableCell style={{width: 300}}>Status</TableCell>
                                            <TableCell style={{width: 150}}>&nbsp;</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                    {allTrodeSizes && allTrodeSizes.map((item) => {
                                        if(sizeToEdit.trodeTypeSizeId === item.trodeTypeSizeId) {
                                            initialValues.edit_trodeTypeSize = sizeToEdit.trodeTypeSize;
                                            initialValues.edit_trodeTypeMeasurement = sizeToEdit.trodeTypeMeasurement;
                                            initialValues.edit_isActive = sizeToEdit.isActive;
                                            
                                            return (
                                                <TableRow hover key={item.trodeTypeSizeId}>
                                                    <TableCell>
                                                        <TextField
                                                            fullWidth
                                                            label="Trode TSize"
                                                            name="edit_trodeTypeSize"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            required
                                                            type="text"
                                                            SelectProps={{ native: true }}
                                                            value={values.edit_trodeTypeSize}
                                                            variant="outlined"
                                                            error={Boolean(touched.edit_trodeTypeSize && errors.edit_trodeTypeSize)}
                                                            helperText={touched.edit_trodeTypeSize && errors.edit_trodeTypeSize}
                                                            className={classes.textField}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                        />
                                                    </TableCell>

                                                    <TableCell>
                                                        <TextField
                                                            label="Measurement"
                                                            name="edit_trodeTypeMeasurement"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            required
                                                            type="text"
                                                            SelectProps={{ native: true }}
                                                            value={values.edit_trodeTypeMeasurement}
                                                            variant="outlined"
                                                            error={Boolean(touched.edit_trodeTypeMeasurement && errors.edit_trodeTypeMeasurement)}
                                                            helperText={touched.edit_trodeTypeMeasurement && errors.edit_trodeTypeMeasurement}
                                                            className={classes.textField}
                                                            style={{width: "200px"}}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            disabled
                                                        />
                                                    </TableCell>

                                                    <TableCell>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch 
                                                                    size="small" 
                                                                    checked={values.edit_isActive === 1} 
                                                                    onChange={() => handleEditActivedChange(item, values.edit_isActive)} 
                                                                />
                                                            }
                                                            label={values.edit_isActive === 1 ? 'Active' : 'Inactive'}
                                                        />
                                                        { deactivated && (
                                                        <Alert severity="warning" className={classes.inactiveWarning} 
                                                            style={{width: "100%", padding: 1}}
                                                        >
                                                            Once set to 'Inactive' it will be removed. This cannot be undone.
                                                        </Alert>
                                                        )}
                                                    </TableCell>

                                                    <TableCell>
                                                        <Box display="flex" justifyContent="flex-end">
                                                            <Button
                                                                variant="contained"
                                                                className={classes.actionLink}
                                                                style={{marginRight: "10px"}}
                                                                onClick={() => handleEditOrCancel({}, handleReset)}
                                                            >
                                                                Cancel
                                                            </Button>

                                                            <Button
                                                                variant="contained"
                                                                className={classes.actionLink}
                                                                onClick={() => handleEditSave(values, validateForm, setTouched, handleReset)}
                                                            >
                                                                Save
                                                            </Button>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                        else {
                                            return (
                                                <TableRow hover key={item.trodeTypeSizeId}>
                                                    <TableCell>
                                                        {item.trodeTypeSize}
                                                    </TableCell>

                                                    <TableCell>
                                                        {item.trodeTypeMeasurement}
                                                    </TableCell>

                                                    <TableCell>
                                                        {item.isActive === 0 ? "InActive" : "Active"}
                                                    </TableCell>

                                                    <TableCell>
                                                        <Box display="flex" justifyContent="flex-end">
                                                            <Button
                                                                variant="contained"
                                                                className={classes.actionLink}
                                                                onClick={() => handleEditOrCancel(item)}
                                                            >
                                                                Edit
                                                            </Button>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    })}
                                    </TableBody>
                                </Table>
                            </Box>
                        </PerfectScrollbar>
                    </Card>
                </>
                )}
            </Formik>

            <MessageBox 
                key={`message_box`} 
                open={alert.open} 
                title={alert.title} 
                message={alert.message} 
                severity={alert.severity} 
                handleClose={() => setAlert({...alert, open: false})}
            />
        </>
    );
};
