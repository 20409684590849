import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Container,
  makeStyles, 
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import Page from 'src/components/Page';
import AuthCheck from 'src/components/auth-check';
import Results from './Results';
import Toolbar from './Toolbar';

import { shippedTrodesAtom, trodeSearchSerialNumberAtom } from 'src/app-data/atoms/trodes-atom';
import { useSetRecoilState, useRecoilCallback } from 'recoil';
import { httpClient } from 'src/lib/api-factory';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "1400px",
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  card: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    color: theme.palette.text.error
  }
}));

const ShippedTrodeListView = ({ className }) => {
  const classes = useStyles();
  const setShippedTrodes = useSetRecoilState(shippedTrodesAtom);
  const [isFetching, setIsFetching] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [display, setDisplay] = useState({ show: false, message: "" });
  const [showLoading, setShowLoading] = useState(false);

  const validationMessage = "Must enter serial number.";
  const noMatchMessage = "The serial number you entered did not find a match or it is retired.";
  const noAvailableTrodesMessage = "No shipped Trodes were found.";

  const getShippedTrodes = useRecoilCallback(() => async (serialNumber) => { 
    try {
        var uri = serialNumber ? `/trodes-shipped?sn=${serialNumber}` : '/trodes-shipped'
        const results = await httpClient().get(uri);
        return results.data;
    }
    catch(error) {
        return [];
    }    
  });

  const getAllShippedClickHandler = useRecoilCallback(() => async () => {
    setIsFetching(true);
    setShowLoading(true);
    setDisplay({ show: false, message: "" });
    const results = await getShippedTrodes();
    setShippedTrodes(results);
    setIsFetching(false);

    if(results.length <= 0) {
      setDisplay({ show: true, message: noAvailableTrodesMessage });
      setShowLoading(false);
    }
  });

  const getTrodeBySerialNumberClickHandler = useRecoilCallback(({reset}) => async (sn) => {
    setIsSearching(true);
    setShowLoading(true);
    setDisplay({ show: false, message: "" });

    if(sn) {
      const results = await getShippedTrodes(sn);
      
      if(results && results.length > 0 && results[0].trodeId) {
        setShippedTrodes(results);
      }
      else {
        setShippedTrodes([]);
        setDisplay({ show: true, message: noMatchMessage });
      }
    }
    else {
      setDisplay({ show: true, message: validationMessage });
    }
    
    setShowLoading(false);
    setIsSearching(false);
  });

  const resetDataList = useRecoilCallback(({reset}) => async () => {
    reset(shippedTrodesAtom);
    reset(trodeSearchSerialNumberAtom);
  });

  useEffect(() => {
    resetDataList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthCheck>
    <Page
      className={classes.root}
      title="Receive Trodes"
    >
      <Container maxWidth={false}>
        <Typography color="textPrimary" variant="h4">
          Receive Trodes
        </Typography>

        <Toolbar 
          getAllShippedHandler={getAllShippedClickHandler} 
          getTrodeBySerialNumberHandler={getTrodeBySerialNumberClickHandler}
          isFetching={isFetching} 
          isSearching={isSearching}
        />

        { display.show &&
          <Card className={clsx(classes.card, className)}>
            <Typography variant="body1">
              {display.message}
            </Typography>
          </Card>
        }

        <Box mt={3}>
          <Results showLoading={showLoading} />
        </Box>
      </Container>
    </Page>
    </AuthCheck>
  );
};

export default ShippedTrodeListView;