import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

import { useRecoilState, useSetRecoilState, useRecoilCallback } from 'recoil';
import { trodeSearchSerialNumberAtom, availableTrodeSizesAtom } from 'src/app-data/atoms/trodes-atom';
import { getCustomersPartialAtom } from 'src/app-data/atoms/customers-atom';
import { httpClient } from 'src/lib/api-factory';


const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({ className, getAvailableHandler, isFetching, getTrodeBySerialNumberHandler, isSearching, ...rest }) => {
  const classes = useStyles();
  const [serialNumber, setSerialNumber] = useRecoilState(trodeSearchSerialNumberAtom);

  const setCustomers = useSetRecoilState(getCustomersPartialAtom); 
  const setTrodeSizes = useSetRecoilState(availableTrodeSizesAtom);


  const getCustomers = useRecoilCallback(() => async () => { 
      try {
          const results = await httpClient().get("/customers");
          return results.data;
      }
      catch(error) {
          return [];
      }    
  });

  const getTrodeSizes = useRecoilCallback(() => async () => {
    try {
        const results = await httpClient().get("/trode-sizes");
        return results.data;
    }
    catch(error) {
        return [];
    }    
  });

  useEffect(() => {
    (async () => {
      try {
        const customersResponse = await getCustomers();
        setCustomers(customersResponse);

        const trodeSizesResponse = await getTrodeSizes();
        setTrodeSizes(trodeSizesResponse);
      }
      catch(error) {

      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        {/*<Button className={classes.importButton}>
          Import
        </Button>
        <Button className={classes.exportButton}>
          Export
        </Button>*/}
        { isFetching ?
        <Button color="primary" variant="contained" disabled>
          Fetching...
        </Button>
        :
        <Button color="primary" variant="contained" onClick={getAvailableHandler}>
          Get All Available Trodes
        </Button>
        }
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                autoFocus
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search Trode by serial number"
                variant="outlined"
                value={serialNumber}
                onChange={e => setSerialNumber(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && getTrodeBySerialNumberHandler(serialNumber)}
              />
            </Box>

            <Box maxWidth={500} style={{paddingTop: "10px"}}>
              <Grid container justify="flex-end">
                { isSearching ?
                <Button color="primary" variant="contained" disabled>
                  Searching
                </Button>
                :
                <Button 
                  color="primary" 
                  variant="contained" 
                  onClick={() => getTrodeBySerialNumberHandler(serialNumber)}
                  
                >
                  Search
                </Button>
                }
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
