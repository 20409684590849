import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Container,
  makeStyles, 
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import AuthCheck from 'src/components/auth-check';

import { availableTrodesAtom, trodeSearchSerialNumberAtom } from 'src/app-data/atoms/trodes-atom';
import { useSetRecoilState, useRecoilCallback } from 'recoil';
import { httpClient } from 'src/lib/api-factory';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "1400px",
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  card: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    color: theme.palette.text.error
  }
}));

const TrodeListView = ({ className }) => {
  const classes = useStyles();
  const setAvailableTrodes = useSetRecoilState(availableTrodesAtom);
  const [isFetching, setIsFetching] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [display, setDisplay] = useState({ show: false, message: "" });
  const [showLoading, setShowLoading] = useState(false);

  const validationMessage = "Must enter serial number.";
  const noMatchMessage = "Either the serial number did not find any matches or the Trode is currently not available to ship.";
  const noAvailableTrodesMessage = "No availble Trodes were found for shipping.";

  const getAvailableTrodes = useRecoilCallback(() => async () => { 
    try {
        const results = await httpClient().get('/trodes');
        return results.data;
    }
    catch(error) {
        return error;
    }    
  });

  const getTrodeBySerialNumber = useRecoilCallback(() => async (serialNumber) => { 
    try {
        const results = await httpClient().get(`/trode/serial-number/${serialNumber}/type/available`);
        return results.data;
    }
    catch(error) {
        return error;
    }    
  });

  const getAvailableClickHandler = useRecoilCallback(() => async () => {
    setIsFetching(true);
    setShowLoading(true);
    setDisplay({ show: false, message: "" });
    const results = await getAvailableTrodes();
    setAvailableTrodes(results);
    setIsFetching(false);

    if(results.length <= 0) {
      setDisplay({ show: true, message: noAvailableTrodesMessage });
      setShowLoading(false);
    }
  });

  const getTrodeBySerialNumberClickHandler = useRecoilCallback(({reset}) => async (sn) => {
    setIsSearching(true);
    setShowLoading(true);
    setDisplay({ show: false, message: "" });

    if(sn) {
      const results = await getTrodeBySerialNumber(sn);
      if(results && results.trodeId) {
        setAvailableTrodes([results]);
      }
      else {
        setAvailableTrodes([]);
        setDisplay({ show: true, message: noMatchMessage });
      }
    }
    else {
      setDisplay({ show: true, message: validationMessage });
    }
    setShowLoading(false);
    setIsSearching(false);
    reset(trodeSearchSerialNumberAtom);
  });

  const resetDataList = useRecoilCallback(({reset}) => async () => {
    reset(availableTrodesAtom);
    reset(trodeSearchSerialNumberAtom);
  });

  useEffect(() => {
    resetDataList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthCheck>
    <Page
      className={classes.root}
      title="Available Trodes"
    >
      <Container maxWidth={false}>
        <Typography color="textPrimary" variant="h4">
          Available Trodes For Shipping
        </Typography>

        <Toolbar 
          getAvailableHandler={getAvailableClickHandler} 
          getTrodeBySerialNumberHandler={getTrodeBySerialNumberClickHandler}
          isFetching={isFetching} 
          isSearching={isSearching}
        />

        { display.show &&
          <Card className={clsx(classes.card, className)}>
            <Typography variant="body1">
              {display.message}
            </Typography>
          </Card>
        }

        <Box mt={3}>
          <Results showLoading={showLoading} />
        </Box>
      </Container>
    </Page>
    </AuthCheck>
  );
};

export default TrodeListView;