import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { deepOrange } from '@material-ui/core/colors';
import { Auth } from "aws-amplify";
import { useRecoilState, useRecoilCallback } from 'recoil';
import { loggedInUserAtom } from 'src/app-data/atoms/user-atom';
import { v4 as uuid } from 'uuid';

import UserAvatar from 'src/components/user-avatar';
import { httpClient } from 'src/lib/api-factory';


const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    height: 100,
    width: 100,
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500]
  }
}));

const Profile = ({ className, ...rest }) => {
  const classes = useStyles();
  const [thumb, setThumb] = useState("");
  const [userState, setUserState] = useRecoilState(loggedInUserAtom);

  const readFileDataAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          resolve(event.target.result);
        };

        reader.onerror = (err) => {
          reject(err);
        };

        reader.readAsDataURL(file);
    });
  }

  const uploadHandler = (event) => {
    var fileData = event.target.files[0]; 
    setThumb(URL.createObjectURL(fileData));
    
    var extension = fileData?.name?.substring(fileData.name.lastIndexOf('.') + 1);
    //var size = fileData.size; //need this to validate size

    console.log(fileData);

    (async () => {
      const user = await Auth.currentAuthenticatedUser();
      const { attributes } = user;

      var blob = await readFileDataAsBase64(fileData);
      var data = blob.replace("data:image/png;base64,", "");
      var fileName = `${attributes.sub}_${uuid()}.${extension}`;

      var result = await sendFile({
        fileData: data, 
        fileName: fileName
      });

      console.log("URL:", result);

      const basePath = "https://pulsevet-admin-ui-avatars.s3.amazonaws.com";
      
      if(result && result.url) {
        await Auth.updateUserAttributes(user, {
          'custom:avatar': `${basePath}/${fileName}`
        });

        setUserState({
          ...userState,
          avatar: `${basePath}/${fileName}`
        });
      }
    })();
  };

  const sendFile = useRecoilCallback(() => async (request) => {
    try {
      const results = await httpClient().post('/files', request);
      return results.data;
    }
    catch(error) {
      console.log("File Upload Error", error);
      return {};
    }    
  });

  return (
    <form id="wrapper" name="wrapper">
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <CardContent>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <UserAvatar 
              avatarClassName={classes.avatar} 
              variant="h3"
              src={thumb}
            >
              {
                ({user}) => (
                  <Typography color="textSecondary" variant="body1">
                    {user.email}
                  </Typography>
                )
              }
            </UserAvatar>
          </Box>
        </CardContent>

        <Divider />

        <CardActions>
          <Button
            color="primary"
            fullWidth
            variant="text"
            component="label"
          >
            Upload Picture
            <input type="file" hidden onChange={uploadHandler} />
          </Button>
        </CardActions>

        <Divider />

        <CardActions>
          <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ width: '100%' }}>
            <Grid item xs={12}>
              <center>
              <Typography color="textSecondary" variant="body1" style={{fontSize: 12}}>
                  Images that have the same width and height work best. <br />
                  Please use images with a small file size.
              </Typography>
              </center>
            </Grid>   
          </Grid> 
        </CardActions>
      </Card>
    </form>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
