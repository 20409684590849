/* eslint-disable */

import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import {useNavigate } from 'react-router-dom';


const LogoutView = () => {
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                //keep atoms and remove everything else?
                //var savedData = localStorage.getItem('recoil-persist');
                //localStorage.clear();
                //localStorage.setItem('recoil-persist', savedData);

                await Auth.signOut();
                localStorage.clear();
                navigate('/', { replace: true });
            }
            catch(error) {
                console.log('error signing out: ', error);
            }
        })();
    }, []);
    
    return (<>Signing out. You will be redirected once signed out.</>);
}

export default LogoutView;