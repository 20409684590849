import React, { useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';

import AvailableTrodes from './AvailableTrodes';
import TotalCustomers from './TotalCustomers';
import ShippedTrodes from './ShippedTrodes';
import ReceivedTrodes from './ReceivedTrodes';
import AuthCheck from 'src/components/auth-check';

import LatestOrders from './LatestOrders';

import { useRecoilValue } from 'recoil';
import { getDashboadCountsAtom, getDashboadCustomersAtom } from '../../app-data/atoms/dashboard-atom';
import { useSetPulseVetApiState } from '../../app-data/triggers/api-triggers';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "1400px",
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));


const Dashboard = () => {
  const classes = useStyles();

  const counts = useRecoilValue(getDashboadCountsAtom);
  const customers = useRecoilValue(getDashboadCustomersAtom);
  const refreshCounts = useSetPulseVetApiState(getDashboadCountsAtom);
  const refreshCustomers = useSetPulseVetApiState(getDashboadCustomersAtom);

  useEffect(() => {
    (async () => {
      try {
        await refreshCounts({
          type: "GET",
          uri: "/dashboard/counts"
        });

        await refreshCustomers({
          type: "GET",
          uri: "/dashboard/customers"
        });
      }
      catch(error) {
        
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <AuthCheck>
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <AvailableTrodes count={counts && counts.availableTrodesCount} />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalCustomers count={counts && counts.customersCount} />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <ShippedTrodes count={counts && counts.trodesShippedInLast90Days} />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <ReceivedTrodes count={counts && counts.trodesReceivedInLast90Days} />
          </Grid>

          <Grid item xs={12}>
            <LatestOrders customers={customers} />
          </Grid>
        </Grid>
      </Container>
    </Page>
    </AuthCheck>
  );
};

export default Dashboard;
