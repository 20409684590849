import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

import { useRecoilState } from 'recoil';
import { trodeSearchSerialNumberAtom } from 'src/app-data/atoms/trodes-atom';


const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({ className, getAllShippedHandler, isFetching, getTrodeBySerialNumberHandler, isSearching, ...rest }) => {
  const classes = useStyles();
  const [serialNumber, setSerialNumber] = useRecoilState(trodeSearchSerialNumberAtom);

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        { isFetching ?
        <Button color="primary" variant="contained" disabled>
          Fetching...
        </Button>
        :
        <Button color="primary" variant="contained" onClick={getAllShippedHandler}>
          Get All Shipped Trodes
        </Button>
        }
      </Box>

      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                autoFocus
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search Shipped Trode by serial number"
                variant="outlined"
                value={serialNumber}
                onChange={e => setSerialNumber(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && getTrodeBySerialNumberHandler(serialNumber)}
              />
            </Box>

            <Box maxWidth={500} style={{paddingTop: "10px"}}>
              <Grid container justify="flex-end">
                { isSearching ?
                <Button color="primary" variant="contained" disabled>
                  Searching
                </Button>
                :
                <Button 
                  color="primary" 
                  variant="contained" 
                  onClick={() => getTrodeBySerialNumberHandler(serialNumber)}
                  
                >
                  Search
                </Button>
                }
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
