import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';

import { ReceiveTrodeModal } from './ReceiveTrodeModal';
import Pagination from '@material-ui/lab/Pagination';

import { shippedTrodesAtom } from 'src/app-data/atoms/trodes-atom';
import { useRecoilValue } from 'recoil';
import { formatDate } from  'src/lib/date-functions';

const useStyles = makeStyles((theme) => ({
  root: {},
  actionLink: {
    fontSize: "0.875rem",
    color: "#3f51b5",
    padding: "0",
    textTransform: 'none'
  },
  paginationCount: {
    //paddingRight: theme.spacing(2),
    padding: "5px 10px 0 0"
  }
}));

const Results = ({ className, showLoading, ...rest }) => {
  const classes = useStyles();
  const [page, setPage] = useState(1); 
  const [pageCount, setPageCount] = useState(0); 
  const [currentSet, setCurrentSet] = useState([]);
  const trodes = useRecoilValue(shippedTrodesAtom);
  const pageItemSize = 100;

  const getCurrentSet = (value, dataArray) => {
    var _currentSet = [];
    var _to = value * pageItemSize;
    var _from = _to - pageItemSize;

    for(let i = _from; i < _to; i++) {
        if(typeof dataArray[i] !== 'undefined') {
            _currentSet.push(dataArray[i]);
        }
    }
    
    setCurrentSet(_currentSet);
  };

  const handlePageChange = (event, value) => { 
    setPage(value);
    getCurrentSet(value, trodes);
  };

  useEffect(() => { 
    (async () => {
        try {
            getCurrentSet(1, trodes);
            setPageCount(Math.ceil(trodes.length / pageItemSize));
        }
        catch(error) {

        }
    })();
  }, [trodes]);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Receive</TableCell>
                <TableCell>Date Shipped</TableCell>
                <TableCell>Trode</TableCell>
                <TableCell>Serial Number</TableCell>
                <TableCell>Customer #</TableCell>
                <TableCell>Customer Name</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              { showLoading && currentSet.length === 0 && (
                <TableRow>
                  <TableCell colSpan="6" style={{textAlign: "center"}}>
                    <CircularProgress /> Loading Data...
                  </TableCell>
                </TableRow>
              )}

              {currentSet && currentSet.map((trode) => (
                <TableRow
                  hover
                  key={`row_shipped_${trode.trodeId}`}
                >
                  <TableCell>
                    <Box
                      alignItems="center"
                      display="flex"
                    >
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        <ReceiveTrodeModal key={`shipped_${trode.trodeId}`} trode={trode}>
                          {
                            ({handleClickOpen}) => (
                              <Button variant="text" className={classes.actionLink} onClick={handleClickOpen}>
                                Receive Trode
                              </Button>
                            )
                          }
                        </ReceiveTrodeModal>
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell>
                    {formatDate(trode.dateSentOut)}
                  </TableCell>

                  <TableCell>
                    {`${trode.trodeTypeName} ${trode.trodeTypeSize}${trode.trodeTypeMeasurement}`}
                  </TableCell>

                  <TableCell>
                    {trode.serialNumber}
                  </TableCell>

                  <TableCell>
                    {trode.customerNumber}
                  </TableCell>

                  <TableCell>
                    {trode.customerName}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      
      

      <Grid container>
        <Grid item xs={6}>
          <Pagination count={pageCount} page={page} onChange={handlePageChange} /> 
        </Grid>

        <Grid item xs={6} container justify="flex-end" className={classes.paginationCount}>
          <Typography color="textPrimary" variant="body1" style={{fontSize: "0.850rem"}}>
            Trodes Found: {trodes && trodes.length}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
