import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  makeStyles,
  Button
} from '@material-ui/core';
import { deepOrange } from '@material-ui/core/colors';

import {
  Router as RouterIcon,
  BarChart,
  Dashboard,
  Group,
  Lock as LockIcon,
  Settings as SettingsIcon
} from '@material-ui/icons';

//same but not recognized unless it's by itself?
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'; 

import NavItem from './NavItem';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { Auth } from "aws-amplify";
import { useRecoilState } from 'recoil';
import { loggedInUserAtom } from 'src/app-data/atoms/user-atom';
import UserAvatar from 'src/components/user-avatar';


const items = [
  {
    href: '/app/dashboard',
    icon: Dashboard,
    title: 'Dashboard',
    //navName: 'nav_dashboard',
    minimumGroup: 'PulseVetUser'
  },
  {
    href: '#',
    icon: Group,
    title: 'Customers',
    navName: 'nav_customers',
    minimumGroup: 'PulseVetUser',
    subItems: [
      {
        href: '/app/customer-new',
        title: 'Add Customer',
        minimumGroup: 'PulseVetUser'
      },
      {
        href: '/app/customers',
        title: 'Customer Search',
        minimumGroup: 'PulseVetUser'
      }
    ]
  },
  {
    href: '#',
    icon: RouterIcon,
    title: 'Trodes',
    navName: 'nav_trode',
    minimumGroup: 'PulseVetUser',
    subItems: [
      {
        href: '/app/trodes-create',
        title: 'Create New',
        minimumGroup: 'PulseVetUser'
      },
      {
        href: '/app/available-trodes',
        title: 'Available / Ship Trodes',
        minimumGroup: 'PulseVetUser'
      },
      {
        href: '/app/shipped-trodes',
        title: 'Receive Trodes',
        minimumGroup: 'PulseVetUser'
      }
    ]
  },
  {
    href: '#',
    icon: BarChart,
    title: 'Reports',
    navName: 'nav_reports',
    minimumGroup: 'PulseVetUser',
    subItems: [
      {
        href: '/app/customer-history',
        title: 'Customer History',
        minimumGroup: 'PulseVetUser'
      },
      {
        href: '/app/outstanding-trodes',
        title: 'Outstanding Trodes',
        minimumGroup: 'PulseVetUser'
      },
      {
        href: '/app/serial-number-history',
        title: 'Serial Number History',
        minimumGroup: 'PulseVetUser'
      },
      {
        href: '/app/returned-trodes',
        title: 'Returned Trodes',
        minimumGroup: 'PulseVetUser'
      },
      {
        href: '/app/return-trode-form',
        title: 'Return Trode Form',
        minimumGroup: 'PulseVetUser'
      },
      {
        href: '/app/manage-notes',
        title: 'Manage Notes',
        minimumGroup: 'PulseVetUser'
      }
    ]
  },
  {
    href: '#',
    icon: SettingsIcon,
    title: 'User Settings',
    navName: 'nav_settings',
    minimumGroup: 'PulseVetUser',
    subItems: [
      {
        href: '/app/user-settings',
        title: 'My Profile',
        minimumGroup: 'PulseVetUser'
      }
    ]
  },
  {
    href: '#',
    icon: SupervisorAccountIcon,
    title: 'User Admin',
    navName: 'nav_admin',
    minimumGroup: 'SuperUser',
    subItems: [
      {
        href: '/app/manage-users',
        title: 'Manage Users',
        minimumGroup: 'SuperUser'
      }
    ]
  }
];



const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  nested: { //here down
    paddingLeft: theme.spacing(4)
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  subTitle: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '1',
    textTransform: 'none',
    width: '100%'
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  const [currentUser, updateCurrentUser] = useRecoilState(loggedInUserAtom);
  const [openItem, setOpen] = React.useState({});
  const inputEl = React.useRef(null);

  const handleClick = (e, name) => {
    setOpen({
      ...openItem,
      ...{ [name]: !openItem[name] }
    });
  };

  const hasMinimumGroup = (group) => {
    if(currentUser?.groups?.includes('SuperUser')) {
      return true;
    }
    else {
      switch(group) {
        case 'Admin': return currentUser?.groups?.includes('Admin');
        case 'PulseVetUser': return currentUser?.groups.length !== 0;
        default: return false;
      }
    }
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    //get user groups for permissions
    Auth.currentAuthenticatedUser().then(cogUser => { 
      const { attributes } = cogUser;
      const groups = cogUser && cogUser.signInUserSession.accessToken.payload["cognito:groups"];
      //console.log("GROUPS", groups); //["PulseVetAdmin"] or undefined

      if(cogUser) { //Use group in items.map(item) below to filter menu items.
        updateCurrentUser({ 
          sub: attributes.sub,
          email: attributes.email,
          family_name: attributes.family_name,
          given_name: attributes.given_name,
          avatar: attributes?.['custom:avatar'] || '',
          groups: groups || []
        });
      } 
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <UserAvatar 
          avatarClassName={classes.avatar} 
          variant="h5"
        >
          <Button href="/logout" color="primary">
            <LockIcon fontSize="small" style={{marginRight: "3px"}} /> Logout
          </Button>
        </UserAvatar>
      </Box>

      <Divider />

      <Box p={2}>
        <List>
          {items.map((item) => { //here
            const Icon = item.icon;

            if(item.subItems) {
              return hasMinimumGroup(item.minimumGroup) && (
                <React.Fragment key={`item_with_sub_items_${uuid()}`}>
                  <ListItem
                    className={classes.item}
                    disableGutters
                    onClick={(e) => handleClick(e, item.navName)}
                    ref={inputEl}
                    key={`${item.title}_${uuid()}`}
                  >
                    <Button className={classes.button}>
                      <Icon className={classes.icon} size="20" />
                      
                      <span className={classes.title}>
                        {item.title}
                      </span>
                    </Button>
                    {openItem[item.navName] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>

                  <Collapse in={openItem[item.navName]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.subItems.map((sub, i) => hasMinimumGroup(sub.minimumGroup) && ( //and here
                        <ListItem button className={classes.nested} key={`subs_${sub.title}_${uuid()}`}>
                          <RouterLink
                            to={sub.href}
                          >
                            <span className={classes.subTitle}>
                              {sub.title}
                            </span>
                          </RouterLink>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </React.Fragment>
              );
            }
            else {
              return hasMinimumGroup(item.minimumGroup) && (
                <NavItem
                  href={item.href}
                  key={`no_subs_${item.title}_${uuid()}`}
                  title={item.title}
                  icon={item.icon}
                />
              );
            }
          })}

        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
