import React, { useState, useEffect } from 'react';
import { 
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    makeStyles
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { useRecoilState, useRecoilCallback } from 'recoil';
import { availableTrodeTypesAtom, availableTrodeSizesAtom } from 'src/app-data/atoms/trodes-atom';
import { postToAPISelector } from 'src/app-data/triggers/api-triggers';
import { httpClient } from 'src/lib/api-factory';


const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        fontSize: "0.850rem"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    reset: {
        marginRight: theme.spacing(1)
    }
}));

export const NewTrode = (props) => {
    const classes = useStyles();
    const [displayMessage, updateDisplayMessage] = useState({
        message: "",
        show: false,
        severity: "", //error, warning, info, success
        showAction: false
    });
    const [_isSubmitting, updateIsSubmitting] = useState(false);
    const [trodeTypes, setTrodeTypes] = useRecoilState(availableTrodeTypesAtom); 
    const [trodeSizes, setTrodeSizes] = useRecoilState(availableTrodeSizesAtom);

    const isUniqueSerialNumber = useRecoilCallback(() => async (serialNumber) => { 
        try {
            const results = await httpClient().get(`/trode/serial-number/${serialNumber}/type/any`);
            return results.data;
        }
        catch(error) {
            return error;
        }    
    });

    const alterTrodeOrCreateNew = useRecoilCallback(({snapshot}) => async (req) => {
        try {
            const results = await snapshot.getPromise(postToAPISelector(req));
            return results;
        }
        catch(error) {
            return error;
        }    
    });

    const getTrodeTypes = useRecoilCallback(() => async () => {
        try {
            const results = await httpClient().get("/trode-types");
            return results.data;
        }
        catch(error) {
            return [];
        }    
     });

    const getTrodeSizes = useRecoilCallback(() => async () => {
        try {
            const results = await httpClient().get("/trode-sizes");
            return results.data;
        }
        catch(error) {
            return [];
        }    
    });

    useEffect(() => {
        (async () => {
            try {
                var _types = await getTrodeTypes();
                setTrodeTypes(_types);

                var _sizes = await getTrodeSizes();
                setTrodeSizes(_sizes);
            }
            catch(error) {
            
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCleanup = (resetFunc) => {
        updateIsSubmitting(false);
        updateDisplayMessage({ message: "", show: false, severity: "" });
        
        if(typeof resetFunc === "function") {
            resetFunc();
        }
    };

    const handleSubmit = (values, validateFunc, setTouched, resetFunc) => { 
        const { trodeType, trodeSize, serialNumber } = values;

        (async () => {
            try {
                const valError = await validateFunc();

                if(Object.keys(valError).length > 0) {
                    setTouched(valError);
                    updateIsSubmitting(false);
                }
                else {
                    updateIsSubmitting(true);

                    var trodeWithSN = await isUniqueSerialNumber(serialNumber.trim());

                    if(Object.keys(trodeWithSN).length > 0) {
                        updateDisplayMessage({ 
                            message: "The serial number you entered is already in use. Please enter a new serial number.",
                            show: true,
                            severity: "warning", 
                            showAction: false 
                        });

                        updateIsSubmitting(false);
                    }
                    else {
                        updateIsSubmitting(true);

                        var addNewResponse = await alterTrodeOrCreateNew({ 
                            uri: `/trodes`,
                            data: {
                                trodeTypeId: trodeType,
                                trodeTypeSizeId: trodeSize,
                                serialNumber: serialNumber.trim()
                            }
                        });

                        if(addNewResponse && addNewResponse.trodeId) {
                            updateDisplayMessage({ 
                                message: "The new Trode has been created.",
                                show: true,
                                severity: "success",
                                showAction: false 
                            });
                    
                            if(typeof resetFunc === "function") {
                                resetFunc();
                            }
                        }
                        else {
                            updateDisplayMessage({ 
                                message: `There was an error creating the new Trode. Take note of the selections you've made and contact an administrator.`,
                                show: true,
                                severity: "error", 
                                showAction: false 
                            });
                        }
                    }
                }
            }
            catch(error) {
                console.log(error);
                updateDisplayMessage({ 
                    message: `There was a system error: ${error && error.message}`,
                    show: true,
                    severity: "error", 
                    showAction: false 
                });
            }
        })();
    };

    return (
        <>
            <Formik
                initialValues={{
                    trodeType: '0',
                    trodeSize: '0',
                    serialNumber: ''
                }}
                validationSchema={Yup.object().shape({
                    trodeType: Yup.string().notOneOf(['0', '', 0], 'Trode Type is required').required('Trode Type is required'),
                    trodeSize: Yup.string().notOneOf(['0', '', 0], 'Trode Size is required').required('Trode Size is required'),
                    serialNumber: Yup.string().max(14, 'Serial number can be a max of 14 characters').required('serialNumber is required')
                })}
            >
                {({errors, handleBlur, handleChange, handleReset, validateForm, setTouched, touched, values}) => (
                <>
                    <Card>
                        <CardHeader
                            subheader="All fields are required to create a new Trode."
                            title="Create New Trode"
                        />

                        <Divider />

                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Select Trode Type"
                                        name="trodeType"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        required
                                        select
                                        SelectProps={{ native: true }}
                                        value={values.trodeType}
                                        variant="outlined"
                                        error={Boolean(touched.trodeType && errors.trodeType)}
                                        helperText={touched.trodeType && errors.trodeType}
                                    >
                                        <option key={`trode_type_0`} value="0">
                                            Select Trode Type
                                        </option>

                                        {trodeTypes && trodeTypes.length > 0 && trodeTypes.map((option) => (
                                        <option key={`trode_type_${option.trodeTypeId}`} value={option.trodeTypeId}>
                                            {option.trodeTypeName}
                                        </option>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Select Trode Size"
                                        name="trodeSize"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        required
                                        select
                                        SelectProps={{ native: true }}
                                        value={values.trodeSize}
                                        variant="outlined"
                                        error={Boolean(touched.trodeSize && errors.trodeSize)}
                                        helperText={touched.trodeSize && errors.trodeSize}
                                    >
                                        <option key={`trode_size_0`} value="0">
                                            Select Trode Size
                                        </option>

                                        {trodeSizes && trodeSizes.length > 0 && trodeSizes.map((option) => (
                                        <option key={`trode_size_${option.trodeTypeSizeId}`} value={option.trodeTypeSizeId}>
                                            {`${option.trodeTypeSize}${option.trodeTypeMeasurement}`}
                                        </option>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Serial Number"
                                        name="serialNumber"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        required
                                        type="text"
                                        SelectProps={{ native: true }}
                                        value={values.serialNumber || ''}
                                        variant="outlined"
                                        error={Boolean(touched.serialNumber && errors.serialNumber)}
                                        helperText={touched.serialNumber && errors.serialNumber}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>

                                {displayMessage.show &&
                                <Grid item xs={12}>
                                    <Alert severity={displayMessage.severity}>
                                        {displayMessage.message}
                                    </Alert>
                                </Grid>
                                }
                            </Grid>
                        </CardContent>

                        <Divider />

                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}
                        >
                            <Button 
                                color="primary" 
                                variant="contained"
                                onClick={() => handleCleanup(handleReset)} 
                                className={classes.reset}
                            >
                                Reset
                            </Button>

                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                onClick={() => handleSubmit(values, validateForm, setTouched, handleReset)}
                                disabled={_isSubmitting}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Card>
                </>
                )}
            </Formik>
        </>
    );
};
