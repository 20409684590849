import { selectorFamily, useRecoilCallback, useSetRecoilState } from 'recoil';
import { httpClient } from '../../lib/api-factory';

export const getToAPISelector = selectorFamily({
  key: 'GET_FROM_API_SELECTOR',
  get: uri => async () => {
      try {
        const response = await httpClient().get(uri);
        return response.data;
      }
      catch(error) {
        //console.log("SELECTOR ERROR", error);
        return [];
      }
  }
});

export const postToAPISelector = selectorFamily({
    key: 'POST_TO_API_SELECTOR',
    get: ({uri, data}) => async () => {
        try {
          const response = await httpClient().post(uri, data);
          return response.data;
        }
        catch(error) {
          //console.log("SELECTOR ERROR", error);
          return [];
        }
    }
});


//custom hooks
export const useSetPulseVetApiState = (atom) => {
  const dispatch = useSetRecoilState(atom);
  
  return useRecoilCallback(() => async (params) => {
    try {
      var response;
      
      if(params.type === "POST") {
        response= await httpClient().post(params.uri, params.data);
      }
      else {
        response = await httpClient().get(params.uri);
      }
      
      dispatch(response.data);
    }
    catch(error) {
      //console.log("Error", error);
      return {};
    }    
  });
};
