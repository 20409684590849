import dayjs from 'dayjs';

export const formatDate = (date) => {
    return dayjs(date)
        .format('YYYY-MM-DD');
}

export const formatDateMinusDays = (date, days) => {
    var d = new Date(date);

    return dayjs(d)
        .subtract(days, 'day')
        .format('YYYY-MM-DD');
}