import React, { useState } from 'react';
//import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Auth } from "aws-amplify";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const StandardLoginForm = (props) => {
    const {touched, values, errors, handleBlur, handleChange, newPasswordRequired} = props;
    return (
        <>
            <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label="Email Address"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
            />
            { !newPasswordRequired ?
            <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
            />
            :
            <></>
            }
        </>
    );
};

export const NewLoginForm = (props) => {
    const {touched, values, errors, handleBlur, handleChange, newPasswordRequired} = props;
    return (
        <>
            { newPasswordRequired ?
            <>
                <TextField
                    error={Boolean(touched.new_password && errors.new_password)}
                    fullWidth
                    helperText={touched.new_password && errors.new_password}
                    label="New Password"
                    margin="normal"
                    name="new_password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.new_password}
                    variant="outlined"
                />
                <TextField
                    error={Boolean(touched.first_name && errors.first_name)}
                    fullWidth
                    helperText={touched.first_name && errors.first_name}
                    label="First Name"
                    margin="normal"
                    name="first_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.first_name}
                    variant="outlined"
                />
                <TextField
                    error={Boolean(touched.last_name && errors.last_name)}
                    fullWidth
                    helperText={touched.last_name && errors.last_name}
                    label="Last Name"
                    margin="normal"
                    name="last_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.last_name}
                    variant="outlined"
                />
            </>
            :
            <></>
            }
        </>
    );
};

export const ForgotPasswordModal = (props) => {
    const messages = {
        DEFAULT: "A code will be sent to the email below. Once you have the code enter it into the next form that will appear.",
        CODE_SENT: "A code has been sent to the email address below. If you did not recieve a code check your junk mail. If it is not there contact the administrator.",
        PASSWORD_CHANGED: "Your password has been updated. Press the Close button below and login again with your new password."
    };
    const [open, setOpen] = useState(false);
    const [codeSent, updateCodeSent] = useState(false);
    const [_isSubmitting, updateIsSubmitting] = useState(false);
    const [displayMessage, updateDisplayMessage] = useState(messages.DEFAULT);

    const handleClickOpen = () => setOpen(true);

    const handleClose = (resetFunc) => {
        setOpen(false);
        handleCleanup(resetFunc);
    };

    const handleForgotSubmit = (values, errors) => {
        const { email } = values;

        if(Object.keys(errors).length < 1) {
            updateIsSubmitting(true);

            Auth.forgotPassword(email) 
                .then(_ => {
                    updateCodeSent(true);
                    updateDisplayMessage(messages.CODE_SENT);
                    updateIsSubmitting(false);
                }) 
                .catch(err => {
                    updateDisplayMessage(err.message);
                });
        }
    };

    const handleResetSubmit = (values, errors) => {
        const { email, code, new_password } = values;

        if(Object.keys(errors).length < 1) {
            updateIsSubmitting(true);

            Auth.forgotPasswordSubmit(email, code, new_password)
                .then(data => {
                    updateDisplayMessage(messages.PASSWORD_CHANGED);
                }) 
                .catch(err => {
                    updateIsSubmitting(false);
                    updateDisplayMessage(err.message);
                });
        }
    };

    const handleCleanup = (resetFunc) => {
        updateCodeSent(false);
        updateIsSubmitting(false);
        updateDisplayMessage(messages.DEFAULT);
        
        if(typeof resetFunc === "function") {
            resetFunc();
        }
    };

    return (
        <>
            <Formik
                initialValues={{
                    email: '',
                    code: '',
                    new_password: '',
                    confirm_password: ''
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    code: codeSent && Yup.string().min(1).max(100).required('Code is required'),
                    new_password: codeSent && Yup.string().max(255).required('New Password is required'),
                    confirm_password: codeSent && Yup.string().oneOf([Yup.ref('new_password'), null], 'Passwords must match')
                })}
            >
                {({errors, handleBlur, handleChange, handleReset, isSubmitting, touched, values}) => (
                <>
                    {props.children && props.children({
                        handleClickOpen: handleClickOpen
                    })}

                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>

                        <DialogContent>
                            <DialogContentText>
                                {displayMessage}
                            </DialogContentText>

                            {!codeSent ?
                            <TextField
                                autoFocus
                                error={Boolean(touched.email && errors.email)}
                                fullWidth
                                helperText={touched.email && errors.email}
                                label="Email Address"
                                margin="dense"
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="email"
                                value={values.email}
                            />
                            : 
                            <>
                                <TextField fullWidth label="Email Address" margin="dense" type="email" value={values.email} disabled />

                                <TextField
                                    autoFocus
                                    error={Boolean(touched.code && errors.code)}
                                    fullWidth
                                    helperText={touched.code && errors.code}
                                    label="Code"
                                    margin="dense"
                                    name="code"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    value={values.code}
                                />

                                <TextField
                                    error={Boolean(touched.new_password && errors.new_password)}
                                    fullWidth
                                    helperText={touched.new_password && errors.new_password}
                                    label="New Password"
                                    margin="dense"
                                    name="new_password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="password"
                                    value={values.new_password}
                                />

                                <TextField
                                    error={Boolean(touched.confirm_password && errors.confirm_password)}
                                    fullWidth
                                    helperText={touched.confirm_password && errors.confirm_password}
                                    label="Confirm New Password"
                                    margin="dense"
                                    name="confirm_password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="password"
                                    value={values.confirm_password}
                                />
                            </>
                            }
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={(e) => handleClose(handleReset)} color="primary">
                                Cancel/Close
                            </Button>

                            {!codeSent ?
                            <Button onClick={(e) => handleForgotSubmit(values, errors)} color="primary" disabled={_isSubmitting}>
                                Get Code
                            </Button>
                            : 
                            <>
                            <Button onClick={(e) => handleCleanup(handleReset)} color="primary">
                                Start Over
                            </Button>

                            <Button onClick={(e) => handleResetSubmit(values, errors)} color="primary" disabled={_isSubmitting}>
                                Submit
                            </Button>
                            </>
                            }
                        </DialogActions>
                    </Dialog>
                </>
                )}
            </Formik>
        </>
    );
};
/* Showing warning but there is no reason for it to
ForgotPasswordModal.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.arrayOf(PropTypes.node)
    ]).isRequired
};*/
