import React, { useState } from 'react';
import { 
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    makeStyles,
    FormControlLabel,
    Switch,
    FormLabel,
    FormControl,
    FormGroup
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { Formik } from 'formik';
import * as Yup from 'yup';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { useRecoilValue, useSetRecoilState, useRecoilCallback } from 'recoil';
import { getCustomersFullAtom, getCustomersDisplayAtom, customerSearchByNameKeyValueAtom } from 'src/app-data/atoms/customers-atom';
import { httpClient } from 'src/lib/api-factory';
import { v4 as uuid } from 'uuid';

import { getUnitedStatesAtom, getCountriesAtom } from 'src/app-data/atoms/locations-atom';


const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        fontSize: "0.850rem"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        //width: 200,
    },
    formControlLabel: {
        fontSize: "0.850rem",
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    formControlContainer: {
        marginLeft: theme.spacing(1)
    }
}));

export const EditCustomerModal = (props) => {
    const { customer } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [savedToDb, setSavedToDb] = useState(false);
    const [switches, setSwitches] = useState({
        isActive: customer.isActive
    });

    const [_isSubmitting, updateIsSubmitting] = useState(false);
    const [disableReset, updateDisableReset] = useState(false);
    const [displayMessage, updateDisplayMessage] = useState({
        message: "",
        show: false,
        severity: "", //error, warning, info, success
        showAction: false
    });


    const unitedStates = useRecoilValue(getUnitedStatesAtom);
    const countries = useRecoilValue(getCountriesAtom);

    const refreshCustomersFull = useSetRecoilState(getCustomersFullAtom);
    const refreshCustomerDisplay = useSetRecoilState(getCustomersDisplayAtom);
    const setNewCustomerSearchKey = useSetRecoilState(customerSearchByNameKeyValueAtom);

    const refreshCustomerLists = useRecoilCallback(() => async () => {
        try {
            const results = await httpClient().get(`/customers?all=true`);
            return results.data;
        }
        catch(error) {
            return [];
        }    
    });

    const handleClickOpen = () => setOpen(true);

    const handleClose = (resetFunc) => {
        setOpen(false);
        handleCleanup(resetFunc);
        
        if(savedToDb) { 
            (async () => {
                var customersRefreshed = await refreshCustomerLists();

                refreshCustomersFull(customersRefreshed);
                refreshCustomerDisplay([]); 
                setNewCustomerSearchKey(`customerSearchByName_${uuid()}`)
            })();
        }
    };

    const handleCleanup = (resetFunc) => {
        updateIsSubmitting(false);
        updateDisplayMessage({ message: "", show: false, severity: "" });
        

        setSwitches({
            isActive: customer.isActive
        });
        
        if(typeof resetFunc === "function") {
            resetFunc();
        }
    };

    const handleProceed = (values) => {
        (async () => {
            updateDisableReset(true);

            var response =  await alterCustomer(customer.customerAccountId, { isActive: 0 });

            if(response && response.id) {
                updateDisplayMessage({ 
                    message: "Customer has been altered.",
                    show: true,
                    severity: "success", 
                    showAction: false 
                });

                setSavedToDb(true);
            }
            else {
                updateDisplayMessage({ 
                    message: "There was an error updating the Customer.",
                    show: true,
                    severity: "error", 
                    showAction: false 
                });
            }
        })();
    };

    const handleEditActivedChange = (item) => {
        setSwitches({
            ...switches,
            ...item
        });
        
        if(item.isActive === 0) {
            updateIsSubmitting(true);
            updateDisplayMessage({ 
                message: "Setting a Customer to inactive cannot be undone once saved.",
                show: true,
                severity: "warning", 
                showAction: true 
            });
        }
        else {
            updateIsSubmitting(false);
            updateDisplayMessage({ 
                message: "",
                show: false,
                severity: "warning", 
                showAction: false 
            });
        }
    };

    const alterCustomer = useRecoilCallback(() => async (id, body) => {
        try {
            const results = await httpClient().post(`/customers/${id}/alter`, body);
            return results.data;
        }
        catch(error) {
            return [];
        }    
    });

    const handleSubmit = (values, validateFunc, setTouched) => { 
        const { customerName, customerNumber, stateId, countryId } = values;
        
        (async () => {
            try {
                const valError = await validateFunc();

                if(Object.keys(valError).length > 0) {
                    setTouched(valError);
                    updateIsSubmitting(false);
                }
                else {
                    updateIsSubmitting(true);

                    var finalStateId = (stateId !== '' && stateId !== '0' && stateId !== 0) ? Number(stateId) : 0;
                    
                    if(countryId !== 230 && countryId !== '230') {
                        finalStateId = 0;
                    }

                    var reqBody = {
                        customerName: customerName,
                        customerNumber: customerNumber,
                        stateId: finalStateId, 
                        countryId: Number(countryId)
                    };

                    var alterOriginalResponse = await alterCustomer(customer.customerAccountId, reqBody);

                    if(alterOriginalResponse && alterOriginalResponse.id) {  
                        updateDisplayMessage({ 
                            message: "The customer has been updated",
                            show: true,
                            severity: "success",
                            showAction: false 
                        });

                        setSavedToDb(true);
                    }
                    else {
                        updateDisplayMessage({ 
                            message: `There was an error updating the customer.`,
                            show: true,
                            severity: "error", 
                            showAction: false 
                        });
                    }
                }
            }
            catch(error) {
                updateDisplayMessage({ 
                    message: `There was a system error: ${error && error.message}`,
                    show: true,
                    severity: "error", 
                    showAction: false 
                });
            }
        })();
    };

    return (
        <>
            <Formik
                initialValues={{
                    customerName: customer.customerName,
                    customerNumber: customer.customerNumber,
                    stateId: customer.stateId || 0,
                    countryId: customer.countryId,
                    isActive: switches.isActive
                }}
                validationSchema={Yup.object().shape({
                    customerName: Yup.string().max(50, 'Customer Name can be a max of 50 characters').required('Customer Name is required'),
                    customerNumber: Yup.number()
                        .required('Customer Number is required')
                        .positive('Customer Number must be a positive number')
                        .integer('Customer Number must be an integer')
                        .typeError('Customer Number only accepts numbers'),
                    countryId: Yup.string().notOneOf(['0', '', 0], 'Country is required').required('Country is required')
                })}
            >
                {({errors, handleBlur, handleChange, handleReset, validateForm, setTouched, touched, values, initialValues}) => (
                <>
                    {props.children && props.children({
                        handleClickOpen: handleClickOpen
                    })}

                    {
                        (() => {
                            initialValues.isActive = switches.isActive;
                        })()
                    }

                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogContent>
                            <Card>
                                <CardHeader
                                    title={customer && `Edit (${customer.customerName} : ${customer.customerNumber})`}
                                    subheader="Editting this customer will affect all existing records of this customer."
                                />

                                <Divider />

                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Customer Name"
                                                name="customerName"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                required
                                                type="text"
                                                SelectProps={{ native: true }}
                                                value={values.customerName}
                                                variant="outlined"
                                                error={Boolean(touched.customerName && errors.customerName)}
                                                helperText={touched.customerName && errors.customerName}
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Customer Number"
                                                name="customerNumber"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                required
                                                type="text"
                                                SelectProps={{ native: true }}
                                                value={values.customerNumber}
                                                variant="outlined"
                                                error={Boolean(touched.customerNumber && errors.customerNumber)}
                                                helperText={touched.customerNumber && errors.customerNumber}
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>

                                        { (values.countryId === 230 || values.countryId === '230') &&
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Select A State"
                                                name="stateId"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                required
                                                select
                                                SelectProps={{ native: true }}
                                                value={values.stateId}
                                                variant="outlined"
                                                error={Boolean(touched.stateId && errors.stateId)}
                                                helperText={touched.stateId && errors.stateId}
                                                style={{maxWidth: 500}}
                                                InputProps={{
                                                    classes: {
                                                    input: classes.listStyle,
                                                    },
                                                }}
                                                >
                                                <option key={`size_0_0`} value="0">
                                                    Select A State
                                                </option>

                                                {unitedStates && unitedStates.length > 0 && unitedStates.map((option) => (
                                                <option key={`state_${option.stateId}`} value={option.stateId}>
                                                    {option.stateName}
                                                </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        }

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Select A Country"
                                                name="countryId"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                required
                                                select
                                                SelectProps={{ native: true }}
                                                value={values.countryId}
                                                variant="outlined"
                                                error={Boolean(touched.countryId && errors.countryId)}
                                                helperText={touched.countryId && errors.countryId}
                                                style={{maxWidth: 500}}
                                                InputProps={{
                                                    classes: {
                                                    input: classes.listStyle,
                                                    },
                                                }}
                                                >
                                                <option key={`size_0_0`} value="0">
                                                    Select A Country
                                                </option>

                                                {countries && countries.length > 0 && countries.map((option) => (
                                                <option key={`country_${option.countryId}`} value={option.countryId}>
                                                    {option.countryName}
                                                </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item md={3} xs={12}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend" className={classes.formControlLabel}>
                                                    Active
                                                </FormLabel>

                                                <FormGroup className={classes.formControlContainer}>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch 
                                                                size="small" 
                                                                checked={values.isActive === 1} 
                                                                onChange={() => handleEditActivedChange({
                                                                    isActive: values.isActive === 1 ? 0 : 1
                                                                })} 
                                                            />
                                                        }
                                                        label={values.isActive === 1 ? 'Yes' : 'No'}
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>

                                        {displayMessage.show &&
                                        <Grid item md={12} xs={12}>
                                            <Alert 
                                                severity={displayMessage.severity}
                                                action={ displayMessage.showAction &&
                                                    <Button 
                                                        variant="contained" 
                                                        color="secondary" 
                                                        size="small"
                                                        onClick={() => handleProceed(values)}
                                                    >
                                                        Proceed
                                                    </Button>
                                                }
                                            >
                                                {displayMessage.message}
                                            </Alert>
                                        </Grid>
                                        }
                                    </Grid>
                                </CardContent>

                                <Divider />

                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    p={2}
                                >
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => handleSubmit(values, validateForm, setTouched)}
                                        disabled={_isSubmitting}
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Card>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => handleCleanup(handleReset)} color="primary" disabled={disableReset}>
                                Reset
                            </Button>

                            <Button onClick={() => handleClose(handleReset)} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
                )}
            </Formik>
        </>
    );
};
