import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';

import { EditCustomerModal } from './EditCustomerModal';
import Pagination from '@material-ui/lab/Pagination';

import { getCustomersDisplayAtom } from 'src/app-data/atoms/customers-atom';
import { useRecoilValue } from 'recoil';

const useStyles = makeStyles((theme) => ({
  root: {},
  actionLink: {
    fontSize: "0.875rem",
    color: "#3f51b5",
    padding: "0",
    textTransform: 'none'
  },
  paginationCount: {
    //paddingRight: theme.spacing(2),
    padding: "5px 10px 0 0"
  }
}));

const Results = ({ className, showLoading, ...rest }) => {
  const classes = useStyles();
  const [page, setPage] = useState(1); 
  const [pageCount, setPageCount] = useState(0); 
  const [currentSet, setCurrentSet] = useState([]);
  const displayData = useRecoilValue(getCustomersDisplayAtom);
  const pageItemSize = 100;
  
  const getCurrentSet = (value, dataArray) => {
    var _currentSet = [];
    var _to = value * pageItemSize;
    var _from = _to - pageItemSize;

    for(let i = _from; i < _to; i++) {
        if(typeof dataArray[i] !== 'undefined') {
            _currentSet.push(dataArray[i]);
        }
    }
    
    setCurrentSet(_currentSet);
  };

  const handlePageChange = (event, value) => { 
    setPage(value);
    getCurrentSet(value, displayData);
  };

  useEffect(() => { 
    (async () => {
        try {
            getCurrentSet(1, displayData); 
            setPageCount(Math.ceil(displayData.length / pageItemSize));
        }
        catch(error) {

        }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayData]);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Edit</TableCell>
                <TableCell>Customer name</TableCell>
                <TableCell>Customer #</TableCell>
                <TableCell>Location</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              { showLoading && currentSet.length === 0 && (
                <TableRow>
                  <TableCell colSpan="5" style={{textAlign: "center"}}>
                    <CircularProgress /> Loading Data...
                  </TableCell>
                </TableRow>
              )}

              {currentSet && currentSet.map((item) => (
                <TableRow
                  hover
                  key={item.customerAccountId}
                >
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Typography color="textPrimary" variant="body1">
                        <EditCustomerModal key={`alter_${item.customerAccountId}`} customer={item}>
                            {
                              ({handleClickOpen}) => (
                                <Button variant="text" className={classes.actionLink} onClick={handleClickOpen}>
                                  Edit
                                </Button>
                              )
                            }
                          </EditCustomerModal>
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell>
                    {item.customerName}
                  </TableCell>

                  <TableCell>
                    {item.customerNumber}
                  </TableCell>

                  <TableCell>
                    {item.stateName ? `${item.stateName}, USA` : item.countryName}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      
      

      <Grid container>
        <Grid item xs={6}>
          <Pagination count={pageCount} page={page} onChange={handlePageChange} /> 
        </Grid>

        <Grid item xs={6} container justify="flex-end" className={classes.paginationCount}>
          <Typography color="textPrimary" variant="body1" style={{fontSize: "0.850rem"}}>
            Items Found: {displayData && displayData.length}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
