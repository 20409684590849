import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Card,
    CardHeader,
    CardContent,
    Container,
    Divider,
    Grid,
    makeStyles,
    TextField,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    FormControlLabel,
    Switch
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import NativeSelect from '@material-ui/core/NativeSelect';
import Page from 'src/components/Page';
import AuthCheck from 'src/components/auth-check';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useRecoilCallback } from 'recoil';
import { httpClient } from 'src/lib/api-factory';
import MessageBox from 'src/components/MessageBox';


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: "1400px",
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    title: {
        fontSize: "0.850rem"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    reset: {
        marginRight: theme.spacing(1)
    },
    actionLink: {
        fontSize: "0.875rem",
        color: "#3f51b5",
        padding: "0",
        textTransform: 'none'
    },
    inactiveWarning: {
        fontSize: "0.775rem",
        color: "#ff0000",
        width: "auto"
    }
}));

const ManageUsersView = (props) => {
    const classes = useStyles();
    const [displayMessage, updateDisplayMessage] = useState({
        message: "",
        show: false,
        severity: "", //error, warning, info, success
        showAction: false
    });
    const [alert, setAlert] = useState({
        severity: "warning",
        title: "",
        message: "",
        open: false
    });
    const [_isSubmitting, updateIsSubmitting] = useState(false);
    const [userToEdit, setUserToEdit] = useState({});
    const [userGroups, setUserGroups] = useState([]);
    const [cognitoUsers, setCognitoUsers] = useState([]);

    const createNewUser = useRecoilCallback(() => async (req) => {
        try {
            const results = await httpClient().post(`/admin/create-user`, req);
            return results.data;
        }
        catch (error) {
            return error;
        }
    });

    const addUserToGroup = useRecoilCallback(() => async (req) => {
        try {
            const results = await httpClient().post(`/admin/add-user-to-group`, req);
            return results.data;
        }
        catch (error) {
            return error;
        }
    });

    const removeUserFromGroup = useRecoilCallback(() => async (req) => {
        try {
            const results = await httpClient().post(`/admin/remove-user-from-group`, req);
            return results.data;
        }
        catch (error) {
            return error;
        }
    });

    const enableDisableUser = useRecoilCallback(() => async (enable, req) => {
        try {
            const url = enable ? "/admin/enable-user" : "/admin/disable-user"
            const results = await httpClient().post(url, req);
            return results.data;
        }
        catch (error) {
            return error;
        }
    });

    const getUserGroups = useRecoilCallback(() => async () => {
        try {
            const results = await httpClient().post(`/admin/list-groups`, { limit: "60" });
            var groups = results?.data?.Groups;
            var sorted = [];

            if(groups) {
                sorted = groups.sort((a, b) => (a.Description < b.Description) ? 1 : -1)
            }

            return sorted;
        }
        catch (error) {
            return error;
        }
    });

    const getUsersByGroup = useRecoilCallback(() => async (groupName) => {
        try {
            const results = await httpClient().post(`/admin/list-users-in-group`, { limit: "60", group: groupName });
            return results?.data?.Users || [];
        }
        catch (error) {
            return error;
        }
    });

    const mapUsersByGroup = async (groupName) => {
        if(groupName) {
            var groups = [];
            var groupUsers = await getUsersByGroup(groupName);

            groupUsers?.map(user => {
                var obj = {
                    enabled: user.Enabled,
                    group: groupName
                };

                user?.Attributes?.map(att => {
                    if(att.Name === "sub") {
                        obj.sub = att.Value;
                    }
                    if(att.Name === "email") {
                        obj.email = att.Value;
                    }
                    if(att.Name === "given_name") {
                        obj.firstName = att.Value;
                    }
                    if(att.Name === "family_name") {
                        obj.lastName = att.Value;
                    }

                    return {};
                });
                
                groups.push(obj);
                return {};
            });

            setCognitoUsers(groups);
        }
    };

    const groupChangeHandler = async (e) => {
        if(e.target.value !== "0") {
            mapUsersByGroup(e.target.value)
        }
    };

    const getNewPassword = () => `T${uuid()}`;

    const handleCleanup = (resetFunc) => {
        updateIsSubmitting(false);
        updateDisplayMessage({ message: "", show: false, severity: "" });
        resetFunc?.();
    };

    const handleEditOrCancel = (user, resetFunc) => {
        setUserToEdit(user);
        resetFunc?.();
    };

    const handleEditActivedChange = (e) => {
        setUserToEdit({
            ...userToEdit,
            enabled: e.target.checked
        });
    };

    const handleEditGroupChange = (e) => {
        setUserToEdit({
            ...userToEdit,
            group: e.target.value
        });
    };

    const handleEditSave = (values, employee, resetFunc) => {
        const { edit_enabled, edit_group, } = values;

        const showMessage = (message) => {
            setAlert({
                ...alert,
                open: true,
                title: "Success",
                message: message,
                severity: "success"
            });
        }

        (async () => {
            try {
                if(!edit_enabled && edit_enabled !== employee.enabled) {
                    await enableDisableUser(edit_enabled, {
                        userName: employee.email
                    });

                    mapUsersByGroup(employee.group);
                    showMessage("The status of the user was successfully changed.");
                }
                else if(edit_enabled && edit_group !== employee.group) {
                    if(edit_enabled && edit_enabled !== employee.enabled) {
                        await enableDisableUser(edit_enabled, {
                            userName: employee.email
                        });
                    }

                    await removeUserFromGroup({
                        group: employee.group,
                        userName: employee.email
                    });

                    await addUserToGroup({
                        group: edit_group,
                        userName: employee.email
                    });

                    mapUsersByGroup(employee.group);
                    showMessage("The user details have been changed. If the user's group was changed the user will no longer show in the group you currently have selected.");
                }

                handleEditOrCancel({}, resetFunc);
            }
            catch (error) {
                setAlert({
                    ...alert,
                    open: true,
                    title: "Error Message",
                    message: "There was an error and the system failed to make the update.",
                    severity: "error"
                });
            }
        })();
    };

    const handleSubmit = (values, validateFunc, setTouched, resetFunc) => {
        const { firstName, lastName, emailAddress, tempPassword } = values;

        (async () => {
            try {
                const valError = await validateFunc();

                if (Object.keys(valError).length > 0) {
                    setTouched(valError);
                    updateIsSubmitting(false);
                }
                else {
                    updateIsSubmitting(true);

                    try {
                        await createNewUser({
                            firstName: firstName.trim(),
                            lastName: lastName.trim(),
                            userName: emailAddress.trim(),
                            temporaryPassword: tempPassword.trim()
                        });

                        //When new user created add them to the lowest access group by default
                        await addUserToGroup({
                            group: "PulseVetUser",
                            userName: emailAddress.trim()
                        });

                        updateDisplayMessage({
                            message: "The new user has been created.",
                            show: true,
                            severity: "success"
                        });

                        resetFunc?.();
                        updateIsSubmitting(false);
                    }
                    catch(error) {
                        updateDisplayMessage({
                            message: `There was an error creating the new user.`,
                            show: true,
                            severity: "error",
                            showAction: false
                        });

                        updateIsSubmitting(false);
                    }
                }
            }
            catch (error) {
                updateDisplayMessage({
                    message: `There was a system error: ${error && error.message}`,
                    show: true,
                    severity: "error",
                    showAction: false
                });
            }
        })();
    };

    useEffect(() => {
        (async () => {
            try {
                var groups = await getUserGroups();
                setUserGroups(groups);
            }
            catch (error) {

            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AuthCheck>
        <Page className={classes.root} title="Manage Users">
            <Container maxWidth={false}>
                <Typography color="textPrimary" variant="h4">
                    Manage Users
                </Typography>

                <Formik
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        emailAddress: '',
                        tempPassword: getNewPassword(),
                        edit_enabled: userToEdit.enabled,
                        edit_group: userToEdit.group || ''
                    }}
                    validationSchema={Yup.object().shape({ //todo: get email max
                        firstName: Yup.string().max(50, 'First Name can be a max of 50 characters').required('First Name is required'),
                        lastName: Yup.string().max(50, 'Last Name can be a max of 50 characters').required('Last Name is required'),
                        emailAddress: Yup.string().email().max(256, 'Email can be a max of 256 characters').required('Email is required'),
                        tempPassword: Yup.string().max(256, 'Temp Password can be a max of 256 characters').required('Temp Password is required')
                    })}
                >
                    {({ errors, handleBlur, handleChange, handleReset, validateForm, setTouched, touched, values, initialValues }) => (
                    <>
                        <Box mt={3}>
                        <>
                            <Card>
                                <CardHeader
                                    title="Add New User"
                                    subheader="All fields are required."
                                />

                                <Divider />

                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="First Name"
                                                name="firstName"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                required
                                                type="text"
                                                SelectProps={{ native: true }}
                                                value={values.firstName}
                                                variant="outlined"
                                                error={Boolean(touched.firstName && errors.firstName)}
                                                helperText={touched.firstName && errors.firstName}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Last Name"
                                                name="lastName"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                required
                                                type="text"
                                                SelectProps={{ native: true }}
                                                value={values.lastName}
                                                variant="outlined"
                                                error={Boolean(touched.lastName && errors.lastName)}
                                                helperText={touched.lastName && errors.lastName}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Email Address"
                                                name="emailAddress"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                required
                                                type="text"
                                                SelectProps={{ native: true }}
                                                value={values.emailAddress}
                                                variant="outlined"
                                                error={Boolean(touched.emailAddress && errors.emailAddress)}
                                                helperText={touched.emailAddress && errors.emailAddress}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                placeholder="This is the User Name"
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Temporary Password"
                                                name="tempPassword"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                required
                                                type="text"
                                                SelectProps={{ native: true }}
                                                value={values.tempPassword}
                                                variant="outlined"
                                                error={Boolean(touched.tempPassword && errors.tempPassword)}
                                                helperText={touched.tempPassword && errors.tempPassword}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>

                                        {displayMessage.show &&
                                            <Grid item xs={12}>
                                                <Alert severity={displayMessage.severity}>
                                                    {displayMessage.message}
                                                </Alert>
                                            </Grid>
                                        }
                                    </Grid>
                                </CardContent>

                                <Divider />

                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    p={2}
                                >
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => {
                                            handleCleanup(handleReset);
                                            initialValues.tempPassword = getNewPassword();
                                        }}
                                        className={classes.reset}
                                    >
                                        Reset
                                    </Button>

                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        onClick={() => {
                                            handleSubmit(values, validateForm, setTouched, handleReset);
                                            initialValues.tempPassword = getNewPassword();
                                        }}
                                        disabled={_isSubmitting}
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Card>
                        </>
                        </Box>

                        <Box mt={3}>
                            <Card>
                                <CardContent>
                                    <PerfectScrollbar>
                                        <Box>
                                            <Table>
                                                <TableHead>
                                                    <TableRow style={{ backgroundColor: "aliceBlue" }}>
                                                        <TableCell style={{ width: 200 }}>Name</TableCell>
                                                        <TableCell style={{ width:150 }}>Email</TableCell>
                                                        <TableCell style={{ width:150 }}>Status</TableCell>
                                                        <TableCell style={{ width:200 }}>Current Group</TableCell>

                                                        <TableCell style={{ width: 250, textAlign: "right" }}>
                                                            <TextField
                                                                label="Groups"
                                                                name="cognitoGroups"
                                                                onChange={groupChangeHandler}
                                                                required
                                                                select
                                                                SelectProps={{ native: true }}
                                                                variant="outlined"
                                                                style={{ minWidth: 200, maxWidth: 500 }}
                                                                InputProps={{
                                                                    classes: {
                                                                        input: classes.listStyle
                                                                    }
                                                                }}
                                                                helperText="Access From Lowest To Highest"
                                                            >
                                                                <option key={`size_0_0`} value="0">
                                                                    Select A Group
                                                                </option>

                                                                {userGroups && userGroups.length > 0 && userGroups.map((option) => (
                                                                <option key={`ug_${option.GroupName}`} value={option.GroupName}>
                                                                    {option.GroupName}
                                                                </option>
                                                                ))}
                                                            </TextField>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {cognitoUsers && cognitoUsers.map((employee) => {
                                                        if (userToEdit.sub === employee.sub) {
                                                            initialValues.edit_enabled = userToEdit.enabled;
                                                            initialValues.edit_group = userToEdit.group;

                                                            return (
                                                                <TableRow hover key={employee.sub}>
                                                                    <TableCell>
                                                                        {`${employee.firstName} ${employee.lastName}`}
                                                                    </TableCell>

                                                                    <TableCell>
                                                                        {employee.email}
                                                                    </TableCell>

                                                                    <TableCell>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Switch
                                                                                    size="small"
                                                                                    checked={values.edit_enabled}
                                                                                    onChange={(e) => handleEditActivedChange(e)}
                                                                                />
                                                                            }
                                                                            label={values.edit_enabled  ? 'Active' : 'Inactive'}
                                                                        />
                                                                    </TableCell>

                                                                    <TableCell>
                                                                        { values.edit_enabled ?
                                                                        <NativeSelect
                                                                            value={values.edit_group}
                                                                            onChange={(e) => handleEditGroupChange(e)}
                                                                            name="userGroup"
                                                                            inputProps={{ 'aria-label': 'userGroup' }}
                                                                        >
                                                                            {userGroups && userGroups.length > 0 && userGroups.map((option) => (
                                                                            <option key={`ugedit_${option.GroupName}`} value={option.GroupName}>
                                                                                {option.GroupName}
                                                                            </option>
                                                                            ))}
                                                                        </NativeSelect>
                                                                        :
                                                                        <></>
                                                                        }
                                                                    </TableCell>

                                                                    <TableCell>
                                                                        <Box display="flex" justifyContent="flex-end">
                                                                            <Button
                                                                                variant="contained"
                                                                                className={classes.actionLink}
                                                                                style={{ marginRight: "10px" }}
                                                                                onClick={() => handleEditOrCancel({}, handleReset)}
                                                                            >
                                                                                Cancel
                                                                            </Button>

                                                                            <Button
                                                                                variant="contained"
                                                                                className={classes.actionLink}
                                                                                onClick={() => 
                                                                                    handleEditSave(
                                                                                        {
                                                                                            edit_enabled: values.edit_enabled, 
                                                                                            edit_group: values.edit_group
                                                                                        }, 
                                                                                        employee, 
                                                                                        handleReset
                                                                                    )
                                                                                }
                                                                            >
                                                                                Save
                                                                            </Button>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <TableRow hover key={employee.sub}>
                                                                    <TableCell>
                                                                        {`${employee.firstName} ${employee.lastName}`}
                                                                    </TableCell>

                                                                    <TableCell>
                                                                        {employee.email}
                                                                    </TableCell>

                                                                    <TableCell>
                                                                        {employee.enabled ? "Active" : "Inactive"}
                                                                    </TableCell>

                                                                    <TableCell>
                                                                        {employee.group}
                                                                    </TableCell>

                                                                    <TableCell>
                                                                        <Box display="flex" justifyContent="flex-end">
                                                                            <Button
                                                                                variant="contained"
                                                                                className={classes.actionLink}
                                                                                onClick={() => handleEditOrCancel(employee)}
                                                                            >
                                                                                Edit
                                                                            </Button>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        }
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </PerfectScrollbar>
                                </CardContent>
                            </Card>
                        </Box>

                        <MessageBox
                            key={`message_box`}
                            open={alert.open}
                            title={alert.title}
                            message={alert.message}
                            severity={alert.severity}
                            handleClose={() => setAlert({ ...alert, open: false })}
                        />
                    </>
                    )}
                </Formik>
            </Container>
        </Page>
        </AuthCheck>
    );
};

export default ManageUsersView;