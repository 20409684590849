import React, { useState } from 'react';
import { 
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    makeStyles
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Formik } from 'formik';
import * as Yup from 'yup';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { useRecoilValue, useRecoilState, useSetRecoilState, useRecoilCallback } from 'recoil';
import { trodeSearchSerialNumberAtom, availableTrodesAtom } from 'src/app-data/atoms/trodes-atom';
import { getCustomersPartialAtom } from 'src/app-data/atoms/customers-atom';
import { useSetPulseVetApiState } from 'src/app-data/triggers/api-triggers';
import { formatDate } from 'src/lib/date-functions';
import { httpClient } from 'src/lib/api-factory';


const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        fontSize: "0.850rem"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    }
}));

export const ShipTrodeModal = (props) => {
    const { trode } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [savedToDb, setSavedToDb] = useState(false);

    const [_isSubmitting, updateIsSubmitting] = useState(false);
    const [displayMessage, updateDisplayMessage] = useState({
        message: "",
        show: false,
        severity: ""
    });

    const setSerialNumber = useSetRecoilState(trodeSearchSerialNumberAtom);
    const customers = useRecoilValue(getCustomersPartialAtom); 
    const [trodeList, setAvailableTrodes] = useRecoilState(availableTrodesAtom);
    const refreshTrodes = useSetPulseVetApiState(availableTrodesAtom);

    const handleClickOpen = () => setOpen(true);

    const handleClose = (resetFunc) => {
        setOpen(false);
        handleCleanup(resetFunc);

        if(savedToDb) {
            if(trodeList.length === 1) {
                setAvailableTrodes([]);
            }
            else {
                refreshTrodes({
                    type: "GET",
                    uri: "/trodes",
                    data: {}
                });
            }
        }
    };

    const handleCleanup = (resetFunc) => {
        updateIsSubmitting(false);
        updateDisplayMessage({ message: "", show: false, severity: "" });
        setSerialNumber("");
        
        if(typeof resetFunc === "function") {
            resetFunc();
        }
    };

    const shipTrode = useRecoilCallback(() => async (trodeId, req) => {
        try {
            const results = await httpClient().post(`/trode/${trodeId}/ship`, req);
            return results.data;
        }
        catch(error) {
            return error;
        }    
    });

    const handleSubmit = (values, resetFunc, validateFunc, setTouched) => {
        const { customer, dateShipped } = values;
        
        validateFunc().then((valError) => {
            if(Object.keys(valError).length > 0) {
                setTouched(valError);
                updateIsSubmitting(false);
            }
            else {
                updateIsSubmitting(true);

                var req = {
                    customerId: customer, //this is customerAccountId
                    dateSentOut: dateShipped
                };

                shipTrode(trode.trodeId, req).then((res) => { 
                    updateDisplayMessage({ 
                        message: "Trode has been shipped and item will be remove from available Trodes below",
                        show: true,
                        severity: "success"
                    });

                    setSerialNumber("");
                    setSavedToDb(true);

                    if(typeof resetFunc === "function") {
                        resetFunc();
                    }  
                })
                .catch(er => {
                    updateDisplayMessage({
                        message: "There was an error shipping this Trode",
                        show: true,
                        severity: "error"
                    });
                });
            }
        });
    };

    return (
        <>
            <Formik
                initialValues={{
                    customer: '0',
                    dateShipped: formatDate(Date.now())
                }}
                validationSchema={Yup.object().shape({
                    customer: Yup.string().notOneOf(['0', '', 0], 'Customer is required').required('Customer is required'),
                    dateShipped: Yup.date().required("Invalid date (ddmmyyyy)")
                })}
            >
                {({errors, handleBlur, handleChange, handleReset, validateForm, setTouched, touched, values}) => (
                <>
                    {props.children && props.children({
                        handleClickOpen: handleClickOpen
                    })}

                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
                        <DialogContent>
                            <Card>
                                <CardHeader
                                    subheader="Select a customer and shipping date"
                                    title={trode && `Assign Trode (${trode.trodeTypeName} ${trode.trodeTypeSize}${trode.trodeTypeMeasurement} : ${trode.serialNumber}) For Shipping`}
                                />

                                <Divider />

                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                id="combo-box-customers"
                                                options={customers}
                                                fullWidth
                                                getOptionLabel={(option) => `${option.customerName} #${option.customerNumber}`}
                                                onChange={(event, value) => values.customer = value && value.customerAccountId}
                                                renderOption={(option) => (
                                                    <React.Fragment>
                                                      <span style={{width: 60}}>#{option.customerNumber}</span>
                                                      {option.customerName}
                                                    </React.Fragment>
                                                  )} 
                                                renderInput={
                                                    (params) => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                            label="Select Customer"
                                                            name="customer"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            required
                                                            SelectProps={{ native: true }}
                                                            value={values.customer}
                                                            variant="outlined"
                                                            error={Boolean(touched.customer && errors.customer)}
                                                            helperText={touched.customer && errors.customer}
                                                        />
                                                    )
                                                }
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Date Shipped"
                                                name="dateShipped"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                required
                                                type="date"
                                                SelectProps={{ native: true }}
                                                value={values.dateShipped || ''}
                                                variant="outlined"
                                                error={Boolean(touched.dateShipped && errors.dateShipped)}
                                                helperText={touched.dateShipped && errors.dateShipped}
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>

                                        {displayMessage.show &&
                                        <Grid item md={12} xs={12}>
                                            <Alert severity={displayMessage.severity}>{displayMessage.message}</Alert>
                                        </Grid>
                                        }
                                    </Grid>
                                </CardContent>

                                <Divider />

                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    p={2}
                                >
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => handleSubmit(values, handleReset, validateForm, setTouched)}
                                        disabled={_isSubmitting}
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Card>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => handleCleanup(handleReset)} color="primary" disabled={_isSubmitting}>
                                Reset
                            </Button>

                            <Button onClick={() => handleClose(handleReset)} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
                )}
            </Formik>
        </>
    );
};