import { atom } from "recoil";

export const getCountriesAtom = atom({
    key: 'COUNTRIES_ATOM',
    default: []
});

export const getUnitedStatesAtom = atom({
    key: 'UNITED_STATES_ATOM',
    default: []
});